// Import React 
import React, { useContext } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";

// Import Components
import "../Events/Events.scss";
import Container from "../Container/Container";
import config from "../../Localization/localization";
import { Context } from "../../Context/LangContext";
import Asidemenu from "../Asidemenu/Asidemenu";
import Tell from "../Tell/Tell";

function Events({ eventData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;
    const navigate = useNavigate();

    const ToDetails = (item) => {
        navigate(`/eventsprof/${item.id}`, { state: item })
    }

    return (
        <>
            <section className="events">
                <Container>
                    <div className="events__asidemenu-wrap">
                        <Asidemenu />
                    </div>
                    <div className="events__bg-wrap">
                        <div className="events__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].events}</div>
                        <h1 className="events__title" data-aos="zoom-in-up">{config[lang].events}</h1>
                    </div>

                    <ul className="events__list">
                        {
                            eventData?.map((evdat) => {

                                return (
                                    <>
                                        <li className="events__item" data-aos="zoom-in" onClick={() => ToDetails(evdat)}>
                                            {/* <Link to={`/eventsprof/${evdat.id}`} className={"events__link"}> */}
                                            <img className="events__img" src={`${httpUrl}${evdat?.icon}`} alt="img" />

                                            <div className="events__overlay">
                                                <div className="events__overlay-title">
                                                    {lang == "uz" ? evdat?.title_uz : lang !== "ru" ? evdat?.title_en : evdat?.title_ru}
                                                </div>
                                                <div className="events__overlay-desc">
                                                    {lang == "uz" ? evdat?.desc_uz.slice(0,100) : lang !== "ru" ? evdat?.desc_en.slice(0,100) : evdat?.desc_ru.slice(0,100)}
                                                </div>
                                            </div>
                                            {/* </Link> */}
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>

                    <div className="events__tell-wrap">
                        <Tell />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Events