// IMport React 
import React, { useState, useContext } from "react";

// Import Components
import "../Residenttwo/Residenttwo.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";

export default function Residenttwo({ reziMenuData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="residenttwo">
                <Container>
                    <ul className="residenttwo__list" >
                        {
                            reziMenuData?.map((data) => {
                                return (
                                    <>
                                        <li className="residenttwo__item" data-aos="zoom-in" data-aos-offset="0" data-aos-delay="300" data-aos-duration="1000">
                                            <img src={`${httpUrl}${data?.icon}`} alt="" />
                                            <p>
                                                {lang == "uz" ? data?.desc_uz : lang !== "ru" ? data?.desc_en : data?.desc_ru}
                                            </p>
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>
                </Container>
            </section>
        </>
    )
}