// Import React 
import React, { useContext } from "react";

// Import Components
import "../Coworking/Coworking.scss";
import Container from "../../Container/Container";

import Img1 from "../../../Assets/Img/coworking-img-1.png";
import Img2 from "../../../Assets/Img/coworking-img-2.png"
import Img3 from "../../../Assets/Img/coworking-img-3.png"
import Img4 from "../../../Assets/Img/coworking-img-4.png"
import Img5 from "../../../Assets/Img/coworking-img-5.png"
import Img6 from "../../../Assets/Img/coworking-img-6.png"

import Image1 from "../../../Assets/Img/coworking-image-1.png";
import Image2 from "../../../Assets/Img/coworking-image-2.png";
import Image3 from "../../../Assets/Img/coworking-image-3.png";
import Image4 from "../../../Assets/Img/coworking-image-4.png";
import Image5 from "../../../Assets/Img/coworking-image-5.png";
import Image6 from "../../../Assets/Img/coworking-image-6.png";

import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";


function Coworking({ coTitle, workingContent }) {

    let httpUrl = process.env.REACT_APP_HTTPS;
    const { lang, setLang } = useContext(Context);

    const workDesc = [];
    workingContent?.map((dat) => {
        workDesc.push(dat)
    })

    return (
        <>
            <section className="coworking">
                <Container>

                    <h2 className="coworking__title" data-aos="fade-up"
                        data-aos-duration="1000">
                        {lang == "uz" ? coTitle?.title_uz : lang !== "ru" ? coTitle?.title_en : coTitle?.title_ru}

                    </h2>

                    <p className="coworking__desc" data-aos="fade-up"
                        data-aos-duration="2000">
                        {lang == "uz" ? coTitle?.desc_uz : lang !== "ru" ? coTitle?.desc_en : coTitle?.desc_ru}
                    </p>

                    <div className="coworking__wrapper">
                        <div className="coworking__wrap-one">
                            <p data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? workDesc[0]?.title_uz : lang !== "ru" ? workDesc[0]?.title_en : workDesc[0]?.title_ru}
                            </p>

                            <div className="coworking__box-one">
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="300" data-aos-duration="1000" src={Img1} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="500" data-aos-duration="1000" src={Img2} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="700" data-aos-duration="1000" src={Img3} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="1000" data-aos-duration="1000" src={Img4} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="1200" data-aos-duration="1000" src={Img5} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="1400" data-aos-duration="1000" src={Img6} alt="img-coworking" />
                            </div>
                        </div>

                        <div className="coworking__wrap-two">
                            <div className="coworking__box-two">
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="300" data-aos-duration="1000" src={Image1} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="500" data-aos-duration="1000" src={Image2} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="700" data-aos-duration="1000" src={Image3} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="1000" data-aos-duration="1000" src={Image4} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="1200" data-aos-duration="1000" src={Image5} alt="img-coworking" />
                                <img data-aos="zoom-in" data-aos-offset="0" data-aos-delay="1400" data-aos-duration="1000" src={Image6} alt="img-coworking" />
                            </div>

                            <p data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? workDesc[1]?.title_uz : lang !== "ru" ? workDesc[1]?.title_en : workDesc[1]?.title_ru}
                            </p>
                        </div>
                    </div>
                </Container>

            </section>
        </>
    )
}

export default Coworking