// Import React 
import React, { useContext } from "react";

// Import Components
import "../ServicePrice/ServicePrice.scss";
import Container from "../../Container/Container";
import FormModal from "../../FormModal/FormModal";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

export default function ServicePrice({ priceData }) {

    const { lang, setLang } = useContext(Context);
    const elFormModal = React.useRef();

    function onFunction() {
        elFormModal.current.classList.add("open--modal");
        document.body.style.overflow = "hidden";
    }

    return (
        <>
            <section className="service-price">
                <Container>

                    <h2 className="service-price__title" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1000">{config[lang].servicefee}</h2>

                    <div className="service-price__wrapper">
                        <ul className="service-price__list">

                            {
                                priceData?.map((data) => {
                                    return (
                                        <>
                                            <li className="service-price__item" data-aos="fade-right"
                                                data-aos-offset="100" data-aos-duration="1000">
                                                <div className="service-price__box">
                                                    <h4>
                                                        {lang == "uz" ? data?.title_uz : lang !== "ru" ? data?.title_en : data?.title_ru}
                                                    </h4>

                                                    <ul className="service-price__list-two">
                                                        {
                                                            data?.items.map((data) => {
                                                                return (
                                                                    <>
                                                                        <li>
                                                                            <p>
                                                                                {lang == "uz" ? data?.title_uz : lang !== "ru" ? data?.title_en : data?.title_ru}
                                                                            </p>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>

                                                <div className="service-price__box-two">
                                                    <h5>
                                                        {lang == "uz" ? data?.desc_uz : lang !== "ru" ? data?.desc_en : data?.desc_ru}
                                                    </h5>
                                                    <button onClick={onFunction}>{config[lang].sendBtn}</button>
                                                </div>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <FormModal elModal={elFormModal} />

                </Container>


            </section>
        </>
    )
}