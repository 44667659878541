// Import React
import React, { useState, useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Pack
import ScrollTrigger from "react-scroll-trigger";

// Import Components
import "../Startaps/Startaps.scss";
import ContainerSm from "../ContainerSm/ContainerSm";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function Startaps({ startap }) {

    const [counterOn, setConunterOn] = useState(false);
    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <ScrollTrigger onEnter={() => setConunterOn(true)} onExit={() => setConunterOn(false)}>
                <ContainerSm>
                    <section className="startaps">

                        <div className="startaps__bg-wrap">
                            <div className="startaps__bg-title" data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-duration="3000"
                                data-aos-offset="0">{config[lang].startaps}</div>
                            <h2 className="startaps__title" data-aos="zoom-in-up">{config[lang].startaps}</h2>
                        </div>

                        <div className="startaps__box">

                            <div data-aos="fade-up" data-aos-duration="3000">
                                <img className="startaps__img" src={`${httpUrl}${startap?.image}`} alt="img" />
                            </div>

                            <div className="startaps__wrap">
                                <p className="startaps__desc" data-aos="fade-up"
                                    data-aos-duration="3000">
                                    {lang == "uz" ? startap?.desc_uz : lang !== "ru" ? startap?.desc_en : startap?.desc_ru}
                                </p>
                                <div data-aos="fade-up">
                                    <Link to={"/startaps"} className={"startaps__link-btn"}>{config[lang].betSil}</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </ContainerSm>
            </ScrollTrigger>
        </>
    )
}

export default Startaps