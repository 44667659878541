// Import React 
import React, { useEffect, useState } from "react";

// Import Packages
import { motion } from "framer-motion"

// Import Components
import Header from "../../Components/Header/Header";
import EventsProfil from "../../Components/EventsProfil/EventsProfil";


function EventsProf() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }

    return (
        <>
            <motion.section className="events-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <Header />
                <EventsProfil />
            </motion.section>
        </>
    )
}

export default EventsProf