// Import React 
import React, { useState, useContext } from "react";

// Import Components
import "../Infracom/Infracom.scss";
import Container from "../../Container/Container";
import Asidemenu from "../../Asidemenu/Asidemenu";

import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";
import Tell from "../../Tell/Tell";

export default function Infracom({ infra }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="infracom">
                <Container>
                    <div className="infracom__box">
                        <div className="infracom__asidemenu-wrap">
                            <Asidemenu />
                        </div>

                        <div className="infracom__content">
                            <div className="infracom__bg-wrap">
                                <div className="infracom__bg-title" data-aos="fade-zoom-in"
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="300"
                                    data-aos-duration="3000"
                                    data-aos-offset="0">{config[lang].infra}</div>
                                <h1 className="infracom__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].infra}</h1>
                            </div>

                            <div className="infracom__wrapper">
                                <div className="infracom__wrap">
                                    <h4 className="infracom__wrap-title" data-aos="fade-up"
                                        data-aos-offset="300"
                                        data-aos-duration="1000">
                                        {lang == "uz" ? infra?.title_uz : lang !== "ru" ? infra?.title_en : infra?.title_ru}
                                    </h4>
                                    <p className="infracom__wrap-desc" data-aos="fade-up"
                                        data-aos-offset="300"
                                        data-aos-duration="2000">
                                        {lang == "uz" ? infra?.desc_uz : lang !== "ru" ? infra?.desc_en : infra?.desc_ru}
                                    </p>
                                </div>
                                <div data-aos="zoom-in-left" data-aos-offset="300"
                                    data-aos-duration="2000">
                                    <img className="infracom__img" src={`${httpUrl}${infra?.image}`} alt="infra-img" width={550} height={400} />
                                </div>
                            </div>
                        </div>

                        <div className="infracom__tell-wrap">
                            <Tell/>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}