// Import React 
import React, { useEffect, useState } from "react";

// Import Packages
import { motion } from "framer-motion"

// Import Components
import Header from "../../Components/Header/Header";
import Events from "../../Components/Events/Events";


function EventsPage() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    useEffect(() => {
        fetch(`${url}tadbir`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, [])

    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }

    return (
        <>
            <motion.section className="events-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <Header />
                <Events eventData={data?.data} />
            </motion.section>
        </>
    )
}

export default EventsPage