// Import React 
import React from "react";

// Import Pack
import ReactPlayer from 'react-player';

// Import Components
import "../Player/Player.scss";

function ReactPlay({elVideoModal, link}) {

    return (
        <>
            <div className="react-player-modal" ref={elVideoModal}>
                <div className="react-player-modal__wrap">
                        <ReactPlayer controls url={link} width={'100%'} height={'100%'} loop />
                    <button className="react-player-modal__btn" onClick={() => {
                        elVideoModal.current.classList.remove('open--modal');
                        document.body.style.overflow = "auto";
                    }}>✖</button>
                </div>
            </div>
        </>
    )
}

export default ReactPlay