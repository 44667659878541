import React, { useContext } from "react";

// Import Components
import "../Ouraddress/Ouraddress.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

export default function Ouraddress() {

    const { lang, setLang } = useContext(Context);

    return (
        <>
            <Container>
                <section className="ouraddress">
                    <h2 className="ouraddress__title">{config[lang].ourAdress}</h2>

                    <div style={{ position: 'relative', overflow: 'hidden' }}>
                        <a href="https://yandex.uz/maps/org/244577402097/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: 12, position: 'absolute', top: 0 }}></a>
                        <a href="https://yandex.uz/maps/10329/andijan/category/it/184106174/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: 12, position: 'absolute', top: 14 }}>IT-компания в Андижане</a>
                        <iframe src="https://yandex.uz/map-widget/v1/-/CCUVyVawTA" width={'100%'} height={600} frameBorder={1} allowFullScreen="true" style={{ position: 'relative', borderRadius: 20, border: "none"}} />
                    </div>

                </section>
            </Container>
        </>
    )
}