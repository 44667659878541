import React,{useRef} from "react";

import "../Loader/Loader.scss";

import Logojon from "../../Assets/Icon/logo-logo.svg";
import LogoDigital from "../../Assets/Icon/digital-city-logo.svg";
import Logocity from "../../Assets/Icon/city-logo.svg";

export default function Loader() {

    // const elSvg = useRef()

    const elSvg = document.querySelectorAll("#logo path");

    for (let i = 0; i<elSvg.length; i++) {
        // console.log(`${i} ${elSvg[i].getTotalLength()}`);
    }

    return (
        <>
            {/* <div className="loader__wrap">
                    <div className="loader__top">
                        <img className="logo1" src={Logojon} alt="" width={200} />
                        <img className="logo2" src={LogoDigital} alt="" width={250}/>
                        <img className="logo3" src={Logocity} alt="" width={140}/> 
                    </div>
                </div> */}
            <div className="loader">
                <svg id="logo" ref={elSvg} width={541} height={114} viewBox="0 0 541 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-outside-1_462_1917" maskUnits="userSpaceOnUse" x={0} y={0} width={541} height={114} fill="black">
                        <rect fill="white" width={541} height={114} />
                        <path d="M26.7 11.4C34.3 11.4 40.8667 12.8333 46.4 15.7C52 18.5 56.2667 22.5333 59.2 27.8C62.2 33.0667 63.7 39.2667 63.7 46.4C63.7 53.5333 62.2 59.7333 59.2 65C56.2667 70.2 52 74.2 46.4 77C40.8667 79.7333 34.3 81.1 26.7 81.1H5V11.4H26.7ZM26.7 73.6C35.7 73.6 42.5667 71.2333 47.3 66.5C52.0333 61.7 54.4 55 54.4 46.4C54.4 37.7333 52 30.9667 47.2 26.1C42.4667 21.2333 35.6333 18.8 26.7 18.8H14.1V73.6H26.7Z" />
                        <path d="M80.4031 17.4C78.6698 17.4 77.2031 16.8 76.0031 15.6C74.8031 14.4 74.2031 12.9333 74.2031 11.2C74.2031 9.46667 74.8031 8 76.0031 6.8C77.2031 5.6 78.6698 5 80.4031 5C82.0698 5 83.4698 5.6 84.6031 6.8C85.8031 8 86.4031 9.46667 86.4031 11.2C86.4031 12.9333 85.8031 14.4 84.6031 15.6C83.4698 16.8 82.0698 17.4 80.4031 17.4ZM84.8031 26.3V81.1H75.7031V26.3H84.8031Z" />
                        <path d="M122.913 25.4C127.646 25.4 131.779 26.4333 135.312 28.5C138.913 30.5667 141.579 33.1667 143.312 36.3V26.3H152.513V82.3C152.513 87.3 151.446 91.7333 149.312 95.6C147.179 99.5333 144.113 102.6 140.113 104.8C136.179 107 131.579 108.1 126.312 108.1C119.113 108.1 113.113 106.4 108.312 103C103.512 99.6 100.679 94.9667 99.8125 89.1H108.812C109.812 92.4333 111.879 95.1 115.012 97.1C118.146 99.1667 121.913 100.2 126.312 100.2C131.313 100.2 135.379 98.6333 138.513 95.5C141.713 92.3667 143.312 87.9667 143.312 82.3V70.8C141.512 74 138.846 76.6667 135.312 78.8C131.779 80.9333 127.646 82 122.913 82C118.046 82 113.613 80.8 109.613 78.4C105.679 76 102.579 72.6333 100.312 68.3C98.0458 63.9667 96.9125 59.0333 96.9125 53.5C96.9125 47.9 98.0458 43 100.312 38.8C102.579 34.5333 105.679 31.2333 109.613 28.9C113.613 26.5667 118.046 25.4 122.913 25.4ZM143.312 53.6C143.312 49.4667 142.479 45.8667 140.812 42.8C139.146 39.7333 136.879 37.4 134.013 35.8C131.213 34.1333 128.112 33.3 124.712 33.3C121.312 33.3 118.213 34.1 115.413 35.7C112.613 37.3 110.379 39.6333 108.712 42.7C107.046 45.7667 106.212 49.3667 106.212 53.5C106.212 57.7 107.046 61.3667 108.712 64.5C110.379 67.5667 112.613 69.9333 115.413 71.6C118.213 73.2 121.312 74 124.712 74C128.112 74 131.213 73.2 134.013 71.6C136.879 69.9333 139.146 67.5667 140.812 64.5C142.479 61.3667 143.312 57.7333 143.312 53.6Z" />
                        <path d="M172.591 17.4C170.857 17.4 169.391 16.8 168.191 15.6C166.991 14.4 166.391 12.9333 166.391 11.2C166.391 9.46667 166.991 8 168.191 6.8C169.391 5.6 170.857 5 172.591 5C174.257 5 175.657 5.6 176.791 6.8C177.991 8 178.591 9.46667 178.591 11.2C178.591 12.9333 177.991 14.4 176.791 15.6C175.657 16.8 174.257 17.4 172.591 17.4ZM176.991 26.3V81.1H167.891V26.3H176.991Z" />
                        <path d="M203.6 33.8V66.1C203.6 68.7667 204.167 70.6667 205.3 71.8C206.433 72.8667 208.4 73.4 211.2 73.4H217.9V81.1H209.7C204.633 81.1 200.833 79.9333 198.3 77.6C195.767 75.2667 194.5 71.4333 194.5 66.1V33.8H187.4V26.3H194.5V12.5H203.6V26.3H217.9V33.8H203.6Z" />
                        <path d="M225.526 53.5C225.526 47.9 226.659 43 228.926 38.8C231.192 34.5333 234.292 31.2333 238.226 28.9C242.226 26.5667 246.659 25.4 251.526 25.4C256.326 25.4 260.492 26.4333 264.026 28.5C267.559 30.5667 270.192 33.1667 271.926 36.3V26.3H281.126V81.1H271.926V70.9C270.126 74.1 267.426 76.7667 263.826 78.9C260.292 80.9667 256.159 82 251.426 82C246.559 82 242.159 80.8 238.226 78.4C234.292 76 231.192 72.6333 228.926 68.3C226.659 63.9667 225.526 59.0333 225.526 53.5ZM271.926 53.6C271.926 49.4667 271.092 45.8667 269.426 42.8C267.759 39.7333 265.492 37.4 262.626 35.8C259.826 34.1333 256.726 33.3 253.326 33.3C249.926 33.3 246.826 34.1 244.026 35.7C241.226 37.3 238.992 39.6333 237.326 42.7C235.659 45.7667 234.826 49.3667 234.826 53.5C234.826 57.7 235.659 61.3667 237.326 64.5C238.992 67.5667 241.226 69.9333 244.026 71.6C246.826 73.2 249.926 74 253.326 74C256.726 74 259.826 73.2 262.626 71.6C265.492 69.9333 267.759 67.5667 269.426 64.5C271.092 61.3667 271.926 57.7333 271.926 53.6Z" />
                        <path d="M305.604 7.1V81.1H296.504V7.1H305.604Z" />
                        <path d="M344.373 46.2C344.373 39.4 345.907 33.3 348.973 27.9C352.04 22.4333 356.207 18.1667 361.473 15.1C366.807 12.0333 372.707 10.5 379.173 10.5C386.773 10.5 393.407 12.3333 399.073 16C404.74 19.6667 408.873 24.8667 411.473 31.6H400.573C398.64 27.4 395.84 24.1667 392.173 21.9C388.573 19.6333 384.24 18.5 379.173 18.5C374.307 18.5 369.94 19.6333 366.073 21.9C362.207 24.1667 359.173 27.4 356.973 31.6C354.773 35.7333 353.673 40.6 353.673 46.2C353.673 51.7333 354.773 56.6 356.973 60.8C359.173 64.9333 362.207 68.1333 366.073 70.4C369.94 72.6667 374.307 73.8 379.173 73.8C384.24 73.8 388.573 72.7 392.173 70.5C395.84 68.2333 398.64 65 400.573 60.8H411.473C408.873 67.4667 404.74 72.6333 399.073 76.3C393.407 79.9 386.773 81.7 379.173 81.7C372.707 81.7 366.807 80.2 361.473 77.2C356.207 74.1333 352.04 69.9 348.973 64.5C345.907 59.1 344.373 53 344.373 46.2Z" />
                        <path d="M429.72 17.4C427.986 17.4 426.52 16.8 425.32 15.6C424.12 14.4 423.52 12.9333 423.52 11.2C423.52 9.46667 424.12 8 425.32 6.8C426.52 5.6 427.986 5 429.72 5C431.386 5 432.786 5.6 433.92 6.8C435.12 8 435.72 9.46667 435.72 11.2C435.72 12.9333 435.12 14.4 433.92 15.6C432.786 16.8 431.386 17.4 429.72 17.4ZM434.12 26.3V81.1H425.02V26.3H434.12Z" />
                        <path d="M460.729 33.8V66.1C460.729 68.7667 461.296 70.6667 462.429 71.8C463.562 72.8667 465.529 73.4 468.329 73.4H475.029V81.1H466.829C461.762 81.1 457.962 79.9333 455.429 77.6C452.896 75.2667 451.629 71.4333 451.629 66.1V33.8H444.529V26.3H451.629V12.5H460.729V26.3H475.029V33.8H460.729Z" />
                        <path d="M533.255 26.3L500.255 106.9H490.855L501.655 80.5L479.555 26.3H489.655L506.855 70.7L523.855 26.3H533.255Z" />
                    </mask>
                    <path d="M26.7 11.4C34.3 11.4 40.8667 12.8333 46.4 15.7C52 18.5 56.2667 22.5333 59.2 27.8C62.2 33.0667 63.7 39.2667 63.7 46.4C63.7 53.5333 62.2 59.7333 59.2 65C56.2667 70.2 52 74.2 46.4 77C40.8667 79.7333 34.3 81.1 26.7 81.1H5V11.4H26.7ZM26.7 73.6C35.7 73.6 42.5667 71.2333 47.3 66.5C52.0333 61.7 54.4 55 54.4 46.4C54.4 37.7333 52 30.9667 47.2 26.1C42.4667 21.2333 35.6333 18.8 26.7 18.8H14.1V73.6H26.7Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M80.4031 17.4C78.6698 17.4 77.2031 16.8 76.0031 15.6C74.8031 14.4 74.2031 12.9333 74.2031 11.2C74.2031 9.46667 74.8031 8 76.0031 6.8C77.2031 5.6 78.6698 5 80.4031 5C82.0698 5 83.4698 5.6 84.6031 6.8C85.8031 8 86.4031 9.46667 86.4031 11.2C86.4031 12.9333 85.8031 14.4 84.6031 15.6C83.4698 16.8 82.0698 17.4 80.4031 17.4ZM84.8031 26.3V81.1H75.7031V26.3H84.8031Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M122.913 25.4C127.646 25.4 131.779 26.4333 135.312 28.5C138.913 30.5667 141.579 33.1667 143.312 36.3V26.3H152.513V82.3C152.513 87.3 151.446 91.7333 149.312 95.6C147.179 99.5333 144.113 102.6 140.113 104.8C136.179 107 131.579 108.1 126.312 108.1C119.113 108.1 113.113 106.4 108.312 103C103.512 99.6 100.679 94.9667 99.8125 89.1H108.812C109.812 92.4333 111.879 95.1 115.012 97.1C118.146 99.1667 121.913 100.2 126.312 100.2C131.313 100.2 135.379 98.6333 138.513 95.5C141.713 92.3667 143.312 87.9667 143.312 82.3V70.8C141.512 74 138.846 76.6667 135.312 78.8C131.779 80.9333 127.646 82 122.913 82C118.046 82 113.613 80.8 109.613 78.4C105.679 76 102.579 72.6333 100.312 68.3C98.0458 63.9667 96.9125 59.0333 96.9125 53.5C96.9125 47.9 98.0458 43 100.312 38.8C102.579 34.5333 105.679 31.2333 109.613 28.9C113.613 26.5667 118.046 25.4 122.913 25.4ZM143.312 53.6C143.312 49.4667 142.479 45.8667 140.812 42.8C139.146 39.7333 136.879 37.4 134.013 35.8C131.213 34.1333 128.112 33.3 124.712 33.3C121.312 33.3 118.213 34.1 115.413 35.7C112.613 37.3 110.379 39.6333 108.712 42.7C107.046 45.7667 106.212 49.3667 106.212 53.5C106.212 57.7 107.046 61.3667 108.712 64.5C110.379 67.5667 112.613 69.9333 115.413 71.6C118.213 73.2 121.312 74 124.712 74C128.112 74 131.213 73.2 134.013 71.6C136.879 69.9333 139.146 67.5667 140.812 64.5C142.479 61.3667 143.312 57.7333 143.312 53.6Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M172.591 17.4C170.857 17.4 169.391 16.8 168.191 15.6C166.991 14.4 166.391 12.9333 166.391 11.2C166.391 9.46667 166.991 8 168.191 6.8C169.391 5.6 170.857 5 172.591 5C174.257 5 175.657 5.6 176.791 6.8C177.991 8 178.591 9.46667 178.591 11.2C178.591 12.9333 177.991 14.4 176.791 15.6C175.657 16.8 174.257 17.4 172.591 17.4ZM176.991 26.3V81.1H167.891V26.3H176.991Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M203.6 33.8V66.1C203.6 68.7667 204.167 70.6667 205.3 71.8C206.433 72.8667 208.4 73.4 211.2 73.4H217.9V81.1H209.7C204.633 81.1 200.833 79.9333 198.3 77.6C195.767 75.2667 194.5 71.4333 194.5 66.1V33.8H187.4V26.3H194.5V12.5H203.6V26.3H217.9V33.8H203.6Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M225.526 53.5C225.526 47.9 226.659 43 228.926 38.8C231.192 34.5333 234.292 31.2333 238.226 28.9C242.226 26.5667 246.659 25.4 251.526 25.4C256.326 25.4 260.492 26.4333 264.026 28.5C267.559 30.5667 270.192 33.1667 271.926 36.3V26.3H281.126V81.1H271.926V70.9C270.126 74.1 267.426 76.7667 263.826 78.9C260.292 80.9667 256.159 82 251.426 82C246.559 82 242.159 80.8 238.226 78.4C234.292 76 231.192 72.6333 228.926 68.3C226.659 63.9667 225.526 59.0333 225.526 53.5ZM271.926 53.6C271.926 49.4667 271.092 45.8667 269.426 42.8C267.759 39.7333 265.492 37.4 262.626 35.8C259.826 34.1333 256.726 33.3 253.326 33.3C249.926 33.3 246.826 34.1 244.026 35.7C241.226 37.3 238.992 39.6333 237.326 42.7C235.659 45.7667 234.826 49.3667 234.826 53.5C234.826 57.7 235.659 61.3667 237.326 64.5C238.992 67.5667 241.226 69.9333 244.026 71.6C246.826 73.2 249.926 74 253.326 74C256.726 74 259.826 73.2 262.626 71.6C265.492 69.9333 267.759 67.5667 269.426 64.5C271.092 61.3667 271.926 57.7333 271.926 53.6Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M305.604 7.1V81.1H296.504V7.1H305.604Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M344.373 46.2C344.373 39.4 345.907 33.3 348.973 27.9C352.04 22.4333 356.207 18.1667 361.473 15.1C366.807 12.0333 372.707 10.5 379.173 10.5C386.773 10.5 393.407 12.3333 399.073 16C404.74 19.6667 408.873 24.8667 411.473 31.6H400.573C398.64 27.4 395.84 24.1667 392.173 21.9C388.573 19.6333 384.24 18.5 379.173 18.5C374.307 18.5 369.94 19.6333 366.073 21.9C362.207 24.1667 359.173 27.4 356.973 31.6C354.773 35.7333 353.673 40.6 353.673 46.2C353.673 51.7333 354.773 56.6 356.973 60.8C359.173 64.9333 362.207 68.1333 366.073 70.4C369.94 72.6667 374.307 73.8 379.173 73.8C384.24 73.8 388.573 72.7 392.173 70.5C395.84 68.2333 398.64 65 400.573 60.8H411.473C408.873 67.4667 404.74 72.6333 399.073 76.3C393.407 79.9 386.773 81.7 379.173 81.7C372.707 81.7 366.807 80.2 361.473 77.2C356.207 74.1333 352.04 69.9 348.973 64.5C345.907 59.1 344.373 53 344.373 46.2Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M429.72 17.4C427.986 17.4 426.52 16.8 425.32 15.6C424.12 14.4 423.52 12.9333 423.52 11.2C423.52 9.46667 424.12 8 425.32 6.8C426.52 5.6 427.986 5 429.72 5C431.386 5 432.786 5.6 433.92 6.8C435.12 8 435.72 9.46667 435.72 11.2C435.72 12.9333 435.12 14.4 433.92 15.6C432.786 16.8 431.386 17.4 429.72 17.4ZM434.12 26.3V81.1H425.02V26.3H434.12Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M460.729 33.8V66.1C460.729 68.7667 461.296 70.6667 462.429 71.8C463.562 72.8667 465.529 73.4 468.329 73.4H475.029V81.1H466.829C461.762 81.1 457.962 79.9333 455.429 77.6C452.896 75.2667 451.629 71.4333 451.629 66.1V33.8H444.529V26.3H451.629V12.5H460.729V26.3H475.029V33.8H460.729Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                    <path d="M533.255 26.3L500.255 106.9H490.855L501.655 80.5L479.555 26.3H489.655L506.855 70.7L523.855 26.3H533.255Z" stroke="white" strokeWidth={10} mask="url(#path-1-outside-1_462_1917)" />
                </svg>
            </div>


        </>
    )
}
