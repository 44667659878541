// Import React 
import React, { useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Business/Business.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function Business({ busData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="business">
                <Container>
                    <div className="business__bg-wrap">
                        <div className="business__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].bisnesopen}</div>
                        <h2 className="business__title" data-aos="zoom-in-up">{config[lang].bisn}</h2>
                    </div>

                    <div className="business__box">
                        <div className="business__wrap">
                            <p className="business__desc" data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? busData?.desc_uz : lang !== "ru" ? busData?.desc_en : busData?.desc_ru}
                            </p>
                            <div data-aos="fade-up">
                                <Link to={"/business"} className={"business__link-btn"}>{config[lang].betSil}</Link>
                            </div>
                        </div>

                        <img data-aos="fade-up" data-aos-duration="3000" className="business__img" src={`${httpUrl}${busData?.image}`} alt="img" />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Business