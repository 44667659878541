// Import React
import React, { useState, useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Digitization/Digitization.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";


function Digitization({ numData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="digitixation">
                <Container>
                    <div className="digitixation__bg-wrap">
                        <div className="digitixation__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].digitization}</div>
                        <h2 className="digitixation__title" data-aos="zoom-in-up">{config[lang].digitization}</h2>
                    </div>

                    <div className="digitixation__box">
                        <div data-aos="zoom-in" data-aos-duration="3500">
                            <img className="digitixation__img" src={`${httpUrl}${numData?.image}`} alt="img" />
                        </div>

                        <div className="digitixation__wrap">
                            <p className="digitixation__desc" data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? numData?.desc_uz : lang !== "ru" ? numData?.desc_en : numData?.desc_ru}
                            </p>
                            <div data-aos="fade-up">
                                <Link to={"/digitizayion"} className={"digitixation__link-btn"}>{config[lang].betSil}</Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Digitization