// Import React 
import React, { useContext } from "react";

// Import Components
import "../Incubationcom/Incubationcom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

export default function Incubationcom({ incubatData, wordData, iCard }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <Container>
                <section className="incubationcom">
                    <h2 className="incubationcom__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].startapIncub}</h2>

                    <div className="incubationcom__wrap">
                        <ul className="incubationcom__list-one">

                            {
                                incubatData?.map((data) => {
                                    return (
                                        <>
                                            <li data-aos="fade-up" key={data.id}
                                                data-aos-anchor-placement="bottom-bottom" data-aos-duration="1500">
                                                <img src={`${httpUrl}${data?.icon}`} alt="icon" width={140} height={140} />
                                                <p>
                                                    {lang == "uz" ? data?.desc_uz : lang !== "ru" ? data?.desc_en : data?.desc_ru}
                                                </p>
                                            </li>
                                        </>
                                    )
                                })
                            }

                        </ul>

                        <div className="incubationcom__box">
                            <div>
                                <h3 data-aos="fade-up"
                                    data-aos-duration="1000">
                                    {lang == "uz" ? iCard?.title_uz : lang !== "ru" ? iCard?.title_en : iCard?.title_ru}
                                    </h3>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000">
                                    {lang == "uz" ? iCard?.desc_uz : lang !== "ru" ? iCard?.desc_en : iCard?.desc_ru}
                                </p>
                            </div>
                            <div data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500">
                                <img src={`${httpUrl}${iCard?.image}`} alt="img" width={500} height={300} />
                            </div>
                        </div>


                        <ul className="incubationcom__list-two">

                            {
                                wordData?.map((dat) => {
                                    return (
                                        <>
                                            <li className="incubationcom__item-two" data-aos="fade-up"
                                                data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
                                                {lang == "uz" ? dat?.title_uz : lang !== "ru" ? dat?.title_en : dat?.title_ru}
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </section>
            </Container>
        </>
    )
}