// Import React
import React, { useContext, useRef } from "react";

// Import Components
import "../ITacademy/ITacademy.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";
import CursModal from "../../CursModal/CursModal";

export default function ITacademy({ academData, data }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    // const monthNames = ["January", "February", "March", "April", "May", "June",
    //     "July", "August", "September", "October", "November", "December"
    // ];
    // const d = new Date(11);
    // document.write("The current month is " + monthNames[d.getMonth()]);

    const elCursForm = useRef()

    return (
        <>
            <Container>
                <section className="itacademy">
                    <h2 className="itacademy__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">
                        {lang == "uz" ? data?.title_uz : lang !== "ru" ? data?.title_en : data?.title_ru}
                    </h2>
                    <p className="itacademy__desc" data-aos="zoom-in" data-aos-duration="2000" data-aos-offset="300">
                        {lang == "uz" ? data?.desc_uz : lang !== "ru" ? data?.desc_en : data?.desc_ru}
                    </p>

                    <div className="itacademy__content">
                        <ul className="itacademy__list">

                            {
                                academData?.map((data) => {
                                    return (
                                        <>
                                            <li className="itacademy__item" data-aos="flip-right"
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-duration="2000">
                                                <div className="itacademy__item-left">
                                                    <h4 className="itacademy__item-title">
                                                        {lang == "uz" ? data?.title_uz : lang !== "ru" ? data?.title_en : data?.title_ru}
                                                    </h4>
                                                    <h5 className="itacademy__item-subtitle">
                                                        {lang == "uz" ? data?.subtitle_uz : lang !== "ru" ? data?.subtitle_en : data?.subtitle_ru}
                                                    </h5>
                                                    <p className="itacademy__item-duration">{config[lang].duration} <span>{data?.total}</span>{config[lang].month}</p>
                                                    <button className="itacademy__item-btn" onClick={() => {
                                                        elCursForm.current.classList.add('open--form');
                                                        document.body.style.overflow = "hidden";
                                                    }} >{config[lang].yoz}</button>
                                                </div>
                                                <img src={`${httpUrl}${data?.image}`} alt="icon-img" width={300} height={250} />
                                            </li>
                                        </>
                                    )
                                })
                            }

                        </ul>
                    </div>

                    <CursModal elCursForm={elCursForm} />
                </section>
            </Container>
        </>
    )
}