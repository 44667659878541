// Import React 
import React,{useContext} from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Tad/Tad.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function Tad({tadData}) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="tad">
                <Container>
                    <div className="tad__bg-wrap">
                        <div className="tad__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].events}</div>
                        <h2 className="tad__title" data-aos="zoom-in-up">{config[lang].events}</h2>
                    </div>

                    <div className="tad__box">
                        <div className="animate__pulse animate__delay-5s">
                            <img className="tad__img" src={`${httpUrl}${tadData?.image}`} alt="img" width={500} height={400} />
                        </div>

                        <div>
                            <p className="tad__desc" data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? tadData?.desc_uz : lang !== "ru" ? tadData?.desc_en : tadData?.desc_ru}
                            </p>

                            <div data-aos="fade-up">
                                <Link to={"/events"} className={"tad__btn-link"}>{config[lang].betSil}</Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Tad;