// Import React 
import React, { useContext, useEffect, useRef } from "react";

// Import Components
import "../TexnocomHero/TexnocomHero.scss";
import Container from "../../Container/Container";
import Asidemenu from "../../Asidemenu/Asidemenu";
import Tell from "../../Tell/Tell";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

import gsap from "gsap";

export default function TexnocomHero() {

    const { lang, setLang } = useContext(Context);

    return (
        <>
            <section className="texnocom">
                <Container>
                    <div className="texnocom__box">
                        <div className="texnocom__asidemenu">
                            <Asidemenu />
                        </div>

                        <div className="texnocom__wrap">
                            <h2 className="texnocom__header" data-aos="zoom-in" data-aos-duration="500">{config[lang].technopark}</h2>
                            <h1 className="texnocom__title" data-aos="fade-up" data-aos-duration="1500">{config[lang].dc}</h1>
                            <h4 className="texnocom__subtitle" data-aos="fade-up" data-aos-duration="3000" data-aos-offset="10">{config[lang].in}</h4>
                        </div>
                    </div>
                    <div className="texnocom__tel-wrap">
                        <Tell />
                    </div>
                </Container>

            </section>
        </>
    )
}