// Import React 
import React, {useContext} from "react";
import { NavLink as Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";


// Import Components
import "../Projects/Projects.scss";
import ProjectSlider from "../ProjectSlider/ProjectSlider";

function Projects({ proData, pData }) {

    const { lang, setLang } = useContext(Context);

    return (
        <>
            <section className="projects">
                <Container>
                    <div className="projects__bg-wrap">
                        <div className="projects__wrap-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].projects}</div>
                        <h2 className="projects__title" data-aos="zoom-in-up">{config[lang].projects}</h2>
                    </div>
                    <p className="projects__desc" data-aos="fade-up"
                        data-aos-duration="3000">
                            {lang == "uz" ? pData?.desc_uz : lang !== "ru" ? pData?.desc_ru : pData?.desc_en}
                    </p>

                    <ProjectSlider proData={proData} />
                </Container>
            </section>
        </>
    )
}

export default Projects