// Import React
import React, { useContext } from "react";

// Import Components
import "../DigitizationCard/DigitizationCard.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";

export default function DigitizationCard({ cardData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="digitizationcard">
                <Container>

                    <ul>
                        {
                            cardData?.map((dat) => {
                                return (
                                    <>
                                        <li data-aos="fade-up"
                                            data-aos-anchor-placement="bottom-bottom" data-aos-duration="1500">
                                            <img src={`${httpUrl}${dat?.icon}`} alt="digitization-img" width={350} height={300} />
                                            <p>
                                                {lang == "uz" ? dat?.desc_uz : lang !== "ru" ? dat?.desc_en : dat?.desc_ru}
                                            </p>
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>

                </Container>
            </section>
        </>
    )
}