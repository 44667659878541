// Import React
import React, { useState } from "react";

// Import Components
import "../FormModal/FormModal.scss";

// Import Pack
import Swal from 'sweetalert2';

function FormModal({ elModal }) {

    let url = process.env.REACT_APP_URL;
    const [userName, setUserName] = useState('');
    const [tell, setTell] = useState('');
    const [comment, setComment] = useState('');
    const [email, setEmail] = useState('');

    

    function onSubmit(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", userName);
        formdata.append("tel", tell);
        formdata.append("email", email);
        formdata.append("message", comment);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        if (tell == '' || userName == '' || comment == '' || email == '') {
            Swal.fire(
                `Ma'lumot Toldrin`,
            )
        } else {
            fetch(`${url}message`, requestOptions)
                .then(response => response.text())
                .then(function (response) {
                    if (response == 1) {
                        Swal.fire({
                            icon: 'success',
                            title: `Ma'lumot jonatildi tez orada sizblan bog'lanamiz`,
                            showConfirmButton: false,
                            timer: 10000,
                        })
                        setComment('')
                        setUserName('')
                        setTell('')
                        setEmail('')
                    }
                })
                .catch(error => Swal.fire({
                    icon: 'error',
                    title: 'Xatolik Yuzberdi',
                }));
        }
    }


    return (
        <>
            <div className="form-modal" ref={elModal} onClick={(evt) => {
                // if (
                //     evt.target.matches(".form-modal") || evt.target.matches(".form-modal__btn-clos")
                // ) {
                //     elModal.current.classList.remove("open--modal");
                //     document.body.style.overflow = "auto";
                // }
            }}>
                <form className="form-modal__form">
                    <button className="form-modal__btn-clos" onClick={() => {
                        elModal.current.classList.remove("open--modal");
                        document.body.style.overflow = "auto";
                    }}>✖</button>
                    <input className="form-modal__input" type="text" placeholder="Ism-sharf" required onChange={(e) => setUserName(e.target.value)} />
                    <input className="form-modal__input" type="number" placeholder="Telefon" required onChange={(e) => setTell(e.target.value)} />
                    <input className="form-modal__input" type="email" placeholder="E-mail" required onChange={(e) => setEmail(e.target.value)} />
                    <textarea className="form-modal__textarea" cols="30" rows="10" placeholder="Izoh" onChange={(e) => setComment(e.target.value)}></textarea>
                    <button className="form-modal__btn" onClick={(e) => onSubmit(e)}>Arizangizni Yuboring</button>
                </form>
            </div>
        </>
    )
}

export default FormModal