// Import React 
import React, { useContext } from "react";
import { Context } from "../../../Context/LangContext";

// Import Components
import "../Ourmissioncom/Ourmissioncom.scss";
import Container from "../../Container/Container";
import config from "../../../Localization/localization";

export default function Ourmissioncom({ data, ourData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="ourmission">
                <Container>

                    <h2 className="ourmission__title" data-aos="fade-up">
                        {lang == "uz" ? ourData?.title_uz : lang !== "ru" ? ourData?.title_en : ourData?.title_ru}
                    </h2>
                    <p className="ourmission__desc" data-aos="fade-up"  data-aos-duration="2000">
                        {lang == "uz" ? ourData?.desc_uz : lang !== "ru" ? ourData?.desc_en : ourData?.desc_ru}
                    </p>

                    <h4 className="ourmission__subtitle" data-aos="fade-up"  data-aos-duration="1000">{config[lang].audition}</h4>

                    <div className="ourmission__wrapper">
                        <ul className="ourmission__list">

                            {
                                data?.map((dat) => {
                                    return (
                                        <>
                                            <li className="ourmission__item" data-aos="fade-up"
                                                data-aos-duration="1000">
                                                <img data-aos="fade-up"
                                                    data-aos-duration="3000" className="ourmission__item-img" src={`${httpUrl}${dat?.image}`} alt="img" />
                                                <h4 className="ourmission__item-title">{lang == "uz" ? dat?.title_uz : lang !== "ru" ? dat?.title_en : dat?.title_ru}</h4>
                                                <p className="ourmission__item-desc">
                                                    {lang == "uz" ? dat?.desc_uz : lang !== "ru" ? dat?.desc_en : dat?.desc_ru}
                                                </p>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    )
}
