// Import React 
import React, { useContext } from "react";

// Import Components
import "../BusinesscomHero/BusinesscomHero.scss";
import Asidemenu from "../../Asidemenu/Asidemenu";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";
import Tell from "../../Tell/Tell";

export default function BusinesscomHero({ headTitle, headDataCard }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="businesscom">
                <Container>

                    <div className="businesscom__wrapper">
                        <div className="businesscom__bg-wrap">
                            <div className="businesscom__bg-title" data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-duration="3000"
                                data-aos-offset="0">{config[lang].openbiz}</div>
                            <h1 className="businesscom__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].regBiz}</h1>
                        </div>
                        <p className="businesscom__desc" data-aos="fade-up"
                            data-aos-duration="1000">
                            {lang == "uz" ? headTitle?.desc_uz : lang !== "ru" ? headTitle?.desc_en : headTitle?.desc_ru}
                        </p>

                        <div className="businesscom__asidemenu-wrap">
                            <Asidemenu />
                        </div>

                        <ul className="businesscom__list">
                            {
                                headDataCard?.map((data) => {
                                    return (
                                        <>
                                            <li data-aos="fade-up"
                                                data-aos-duration="1200">
                                                <img src={`${httpUrl}${data.image}`} alt="icon" width={45} height={45} />
                                                <h4>{lang == "uz" ? headTitle?.title_uz : lang !== "ru" ? headTitle?.title_en : headTitle?.title_ru}</h4>
                                                <p>{lang == "uz" ? headTitle?.desc_uz : lang !== "ru" ? headTitle?.desc_en : headTitle?.desc_ru}</p>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <div className="businesscom__tell-wrap">
                        <Tell />
                    </div>
                </Container>

            </section>
        </>
    )
}