// Import React 
import React, { useContext } from "react";

// Import Components
import "../Strategycom/Strategycom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

import Img from "../../../Assets/Img/st-img.svg";
import Imgtwo from "../../../Assets/Icon/strategi-img.svg";

export default function Strategycom({ strategData }) {

    const { lang, setLang } = useContext(Context);

    const textObject = [
        {
            text: `${config[lang].text1}`,
        },
        {
            text: `${config[lang].text2}`,
        },
        {
            text: `${config[lang].text3}`,
        },
        {
            text: `${config[lang].text4}`,
        },
        {
            text: `${config[lang].text5}`,
        },
        {
            text: `${config[lang].text6}`,
        },
        {
            text: `${config[lang].text7}`,
        },
        {
            text: `${config[lang].text8}`,
        },
        {
            text: `${config[lang].text9}`,
        },
        {
            text: `${config[lang].text10}`,
        },
        {
            text: `${config[lang].text11}`,
        },
        {
            text: `${config[lang].text12}`,
        },
        {
            text: `${config[lang].text13}`,
        },
        {
            text: `${config[lang].text14}`,
        },
        {
            text: `${config[lang].text15}`,
        },
        {
            text: `${config[lang].text16}`,
        },
        {
            text: `${config[lang].text17}`,
        },
        {
            text: `${config[lang].text18}`,
        },
        {
            text: `${config[lang].text19}`,
        },
        {
            text: `${config[lang].text20}`,
        },
        {
            text: `${config[lang].text21}`,
        },
    ]

    return (
        <>
            <Container>
                <section className="strategy">

                    <h2 className="strategy__title" data-aos="fade-up"
                        data-aos-offset="300"
                        data-aos-duration="1000">
                        {lang == "uz" ? strategData?.title_uz : lang !== "ru" ? strategData?.title_en : strategData?.title_ru}
                    </h2>
                    <p className="strategy__desc" data-aos="fade-up"
                        data-aos-offset="300"
                        data-aos-duration="2000">
                        {lang == "uz" ? strategData?.desc_uz : lang !== "ru" ? strategData?.desc_en : strategData?.desc_ru}
                    </p>

                    <div className="strategy__wrap">

                        {
                            textObject.map((dat) => {
                                return (
                                    <>
                                        <p className="strategy__wrap-desc strategy__text" data-aos="fade-up" data-aos-duration="2000">{dat?.text}</p>
                                    </>
                                )
                            })
                        }

                        <img className="strategy__img" src={Img} alt="img" />
                        <img className="strategy__img-two" src={Imgtwo} alt="img" />
                    </div>

                    <div className="strategy__wrap-two">
                        <ul>

                            {
                                textObject.map((dat) => {
                                    return(
                                        <>
                                            <li>
                                                <p data-aos="fade-up" data-aos-duration="2000">{dat?.text}</p>
                                            </li>
                                        </>
                                    )
                                })
                            }

                        </ul>
                    </div>

                </section>
            </Container>

        </>
    )
}