// Import React
import React, { useContext } from "react";

// Import Components
import "../Groupcom/Groupcom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";


function Groupcom({ group, groupImgData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="groupcom">
                <Container>
                    <h2 className="groupcom__title" data-aos="fade-up"
                        data-aos-duration="1000">
                        {lang == "uz" ? group?.title_uz : lang !== "ru" ? group?.title_en : group?.title_ru}
                    </h2>
                    <p className="groupcom__desc" data-aos="fade-up"
                        data-aos-duration="2000">
                        {lang == "uz" ? group?.desc_uz : lang !== "ru" ? group?.desc_en : group?.desc_ru}
                    </p>

                    <div className="groupcom__content">
                        <p data-aos="fade-up"
                            data-aos-duration="3000">
                            {lang == "uz" ? groupImgData?.title_uz : lang !== "ru" ? groupImgData?.title_en : groupImgData?.title_ru}
                        </p>

                        <img data-aos="fade-up"
                            data-aos-duration="2000" src={`${httpUrl}${groupImgData?.image}`} alt="img" />
                    </div>
                </Container>
            </section>
        </>
    )
}



export default Groupcom