// Import React 
import React,{useContext} from "react";

// Import Components
import "../Directionscom/Directionscom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";


export default function Directionscom({ direcData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="directions">
                <Container>

                    <h2 className="directions__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].startaptitle}</h2>

                    <div className="directions__box">
                        <ul>

                            {
                                direcData?.map((dat) => {
                                    return (
                                        <>
                                            <li data-aos="fade-up"
                                                data-aos-anchor-placement="bottom-bottom" data-aos-duration="1500">
                                                <img src={`${httpUrl}${dat?.icon}`} alt="direction-icon" width={85} height={70} />
                                                <p>
                                                    {lang == "uz" ? dat?.desc_uz : lang !== "ru" ? dat?.desc_en : dat?.desc_ru}
                                                </p>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Container>

            </section>
        </>
    )
}