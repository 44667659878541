const config = {
    uz: {

        // Globl
        sendBtn: "Arizangizni Yuboring",
        betSil: "Batafsil",
        ourAdress: "Bizning Manzil",
        yoz: "Yozilish",
        duration: "Davomiyligi",
        month: "oy",

        // Infra
        text1: "IQTISODIYOTNI RIVOJLANTIRISH VA LIBERALLASHTIRISH : IQTISODIYOT, IJTIMOIY SOXA, BOSHQARUV TIZIMIGA AXBOROT-KOMMUNIKATSION TEXNOLOGIYALARNI JORIY ETISH. KICHIK BIZNES VA XUSUSIY TADBIRKORLIKNI RIVOJLANTIRISH RAG’BATLANTIRISH",
        text2: "Davlat boshQaruvi tizimini takomillashtirish: davlat organlariNING ochiQligini ta’minlash, “elektron Hukumat ” tizimini takomillashtirish, sifatini oshirish",
        text3: "xavsizlik, diniy bag’rikenglik va millitlar aro totuvlikni taminlash",
        text4: "QONUN USTUVORLIGINI TA’MINLASH, SUD-HUQUQ TIZIMINI ISLOH QILISH",
        text5: "O’ZBEKISTON RESPUBLIKASI VAZIRLAR MAHKAMASINING “YAGONA INTERAKTIV DAVLAT XIZMATLARI PORTALI ORQALI ELEKTRON DAVLAT XIZMATLARINI TAKOMILLASHTIRISH CHORA-TADBIRLARI TO’G’RISIDA”GI QARORI LOYIHASI",
        text6: "FAOLIYATNING 40 TURI BO’YICHA ELEKTRON LITSENZIYALASH PORTALINI YO’LGA QO’YISH",
        text7: "O’ZBEKISTON RESPUBLIKASI PREZIDENTINING “2018-2021 YILLARDA “ELEKTRON HUKUMAT” TIZIMINI YANADA RIVOJLANTIRISH BO’YICHA CHORA-TADBIRLAR TO’G’RISIDA”GI QARORI LOYIHASI",
        text8: "DASTURIY MAHSULOTLARNI ISHLAB CHIQARISHNI RAG’BATLANTIRISH UCHUN INNOVATSIYALAR MARKAZINI BUNYOD ETISH",
        text9: "“YAGONA DARCHA” MARKAZLARIDA AVTOMATLASHTIRILGAN AXBOROT VA DAVLAT XIZMATLARI UCHUN TO’LOVLARNING BILLING TIZIMINI YARATISH",
        text10: "HUDUDLARDA “YAGONA DARCHA” MARKAZLARI VA “O’ZBEKISTON POCHTASI” AJ BO’LINMALARIDA 250 TA INFOKIOSKANI O’RNATISH",
        text11: "“ELEKTRON HUKUMAT” DOIRASIDA AXBOROT XAVFSIZLIGI TIZIMINING MONITORINGI",
        text12: "DAVLAT ORGANLARIDA KADRLAR POTENSIALI HISOBINI YURITISH UCHUN YAGONA IDORALARARO “KADRLAR” TIZIMINI JORIY ETISH ",
        text13: "RESPUBLIKA HUDUDLARIDA 66 TA YUQORI QUVVATLI VA 328 TA KAM QUVVATLI RAQAMLI TELEVIDENIE UZATKICHLARINI O’RNATISH VA ISHGA TUSHIRISH ",
        text14: "RESPUBLIKA HUDUDLARIDA MOBIL ALOQA OPERATORLARNING 1843 TA BAZA STANSIYALARINI O’RNATISH ",
        text15: "IMS TEXNOLOGIYALARI NEGIZIDA KOMMUTATSIYA MARKAZLARINI ZAMONAVIYLASHTIRISH ",
        text16: "RESPUBLIKA HUDUDLARIDA 2300 KM OPTIK TOLALI ALOQA LINIYALARINI QUIRSH",
        text17: "YOSHLARNING BO’SH VAQTDAN OQILONA FOYDALANISHLARI VA ULARNING IMKONIYATLARINI OSHIRISH UCHUN O’YINLAR - MILLIY MOBIL INTERNETNI TASHKIL ETISH",
        text18: "KIBERXAVFSIZLIK SOHASIDA QONUNCHILIK BAZASINI TAKOMILLASHTIRISH",
        text19: "YOSHLAR UCHUN TA’LIM VA MAORIF SOHASIDA ZAMONAVIY AXBOROT IMKONIYATLARI HAMDA, MULTIMEDIA MAHSULOTLARINI YARATISH. MILLIY KONTENTNI RIVOJLANTIRISH VA TAG’RIB QILISH",
        text20: "RESPUBLIKA HUDUDLARIDA AHOLI VA TADBIRKORLIK SUB’EKTLARI UCHUN INTERNET ORQALI DAVLAT XIZMATLARIDAN FOYDALANISHNI O’RGATISH BO’YICHA KOMPYUTER SAVODXONLIGI VA MALAKALARNI OSHIRISH ",
        text21: "ELEKTRON SUD PROTSESSI IJRONI AMALGA OSHIRISHNING ZAMONAVIY SHAKLLARI HAMDA USULLARINI JORIY ETISH",


        // Navbar
        main: "ASOSIY",
        technopark: "TEXNOPARK",
        education: "TA’LIM",
        startaps: "STARTAPLAR",
        projects: "LOYIHALAR",
        digitization: "RAQAMLASHTIRISH",
        business: "BIZNES",
        infra: "INFRATUZILMA",

        // Biznes Page
        openbiz: "BIZNES OCHISH",
        regBiz: "BIZNESNI RO‘YXATDAN O‘TKAZISH",
        servicefee: "Xizmat narxi",

        // Digization Page
        ourCall: "Biz Bilan Bog’laning",

        // Form
        name: "Ism-Sharf",
        email: "E-mail manzil",
        tel: "Telfon nomer",
        comment: "Izoh",
        submitap: "Ariza Yuborish",
        subm:"JO’NATISH",

        // Startap Page 
        startaptitle: "Yo’nalishlar",
        startapIncub: "Inkubatsiya Markazlari",

        // Events
        events: "TADBIRLAR",

        // Cyber
        cyber: "KIBERSPORT",

        // Bisness Page
        bisnesopen: "BIZNES OCHISH",
        bisn: "Biznesni ro‘yxatdan o‘tkazish",

        // Infra Page and Btn
        infrabt: "Infratuzilma",

        // Contact
        con: "ALOQA",

        // Texno Page
        dc: "Raqamli shahar",
        in: "Innovatsiyalarga bir qadam yaqinlashing",
        audition: "Bizning Auditoriyamiz",

        // Dc info
        infoadress: "Manzil",
        infoemail: "Telefon Raqam",
        infotell: "E-mail",

        // 

        coWor1: "Texnopark 50 dan ortiq yangi axborot texnologiyalari sohasida xizmat ko‘rsatadigan korxonalarni joylashtirish uchun mo‘ljallangan. Mazkur texnoparkda korxonalarga o‘z faoliyatini amalga oshirish uchun zarur infratuzilma, hamda ofis maydonlari taqdim etilib, ularga moliyaviy, marketing, yuridik va boshqa konsalting xizmatlari ko‘rsatiladi.",
        coWor2: "Bizning Kovorking maydonlarimiz ochiq konsepsiyaga ega va rezident bo‘lgan har bir kishi uchun taqdim qilinadi. Hamkorlik va ijtimoiy muhitni qo‘llab-quvvatlash uchun qulay mebellar, printerlar va Wi-Fi kabi umumiy texnologiyalar va hatto bepul choy, qahva/suv va gazaklar bilan to‘ldiriladi.", 
    },
    en: {

        // Globl
        sendBtn: "Send your application",
        betSil: "More",
        ourAdress: "Our Address",
        yoz:"Submit an application",
        duration: "Duration",
        month: "month",

        text1: "DEVELOPMENT AND LIBERALIZATION OF THE ECONOMY: IMPLEMENTATION OF INFORMATION AND COMMUNICATION TECHNOLOGIES IN THE ECONOMY, SOCIAL SECTOR, MANAGEMENT SYSTEM. ENCOURAGEMENT OF THE DEVELOPMENT OF SMALL BUSINESS AND PRIVATE ENTREPRENEURSHIP",
        text2: `Improving the state management system: ensuring transparency of state bodies, improving the "electronic government" system, improving its quality`,
        text3: "ensuring peace, religious tolerance and inter-ethnic harmony",
        text4: "ENSURING THE RULE OF LAW, REFORMING THE JUDICIAL SYSTEM",
        text5: `DRAFT DECISION OF THE COURT OF MINISTERS OF THE REPUBLIC OF UZBEKISTAN "ON MEASURES TO IMPROVE ELECTRONIC GOVERNMENT SERVICES THROUGH THE SINGLE INTERACTIVE GOVERNMENT SERVICES PORTAL`,
        text6: "LAUNCH OF ELECTRONIC LICENSING PORTAL FOR 40 TYPES OF ACTIVITIES",
        text7: `DRAFT DECISION OF THE PRESIDENT OF THE REPUBLIC OF UZBEKISTAN "ON MEASURES FOR FURTHER DEVELOPMENT OF THE "ELECTRONIC GOVERNMENT" SYSTEM IN 2018-2021"`,
        text8: "ESTABLISHING AN INNOVATION CENTER TO ENCOURAGE THE PRODUCTION OF SOFTWARE PRODUCTS",
        text9: `CREATION OF A BILLING SYSTEM OF AUTOMATED INFORMATION AND PAYMENTS FOR GOVERNMENT SERVICES AT "SINGLE WINDOW" CENTERS`,
        text10: `INSTALLATION OF 250 INFOKIOSKS IN "YAGONA DARCHA" CENTERS AND "UZBEKISTAN POCHTASI" JSC UNITS IN THE REGIONS`,
        text11: `MONITORING OF INFORMATION SECURITY SYSTEM WITHIN "ELECTRONIC GOVERNMENT".`,
        text12: `IMPLEMENTATION OF A UNIFORM INTER-AGENCY "PERSONNEL" SYSTEM FOR KEEPING THE PERSONNEL POTENTIAL ACCOUNT IN STATE BODIES`,
        text13: "INSTALLATION AND COMMISSIONING OF 66 HIGH-POWER AND 328 LOW-POWER DIGITAL TELEVISION TRANSMITTERS IN THE TERRITORIES OF THE REPUBLIC",
        text14: "INSTALLATION OF 1843 BASE STATIONS OF MOBILE COMMUNICATION OPERATORS IN THE TERRITORIES OF THE REPUBLIC",
        text15: "MODERNIZATION OF SWITCHING CENTERS BASED ON IMS TECHNOLOGIES",
        text16: "BUILDING 2300 KM OF OPTICAL FIBER COMMUNICATION LINES IN THE REPUBLIC'S TERRITORIES",
        text17: "GAMES TO ENHANCE YOUTH'S LEISURE TIME WISELY AND INCREASE THEIR OPPORTUNITIES - NATIONAL MOBILE INTERNET ORGANIZATION",
        text18: "IMPROVING THE LEGISLATIVE FRAMEWORK IN THE FIELD OF CYBER SECURITY",
        text19: "CREATION OF MODERN INFORMATION OPPORTUNITIES AND MULTIMEDIA PRODUCTS IN THE FIELD OF EDUCATION AND EDUCATION FOR YOUTH. DEVELOPMENT AND PROMOTION OF NATIONAL CONTENT",
        text20: "IMPROVING COMPUTER LITERACY AND SKILLS FOR TRAINING THE USE OF GOVERNMENT SERVICES THROUGH THE INTERNET FOR THE POPULATION AND BUSINESS SUBJECTS IN THE TERRITORIES OF THE REPUBLIC",
        text21: "IMPLEMENTATION OF MODERN FORMS AND METHODS OF IMPLEMENTATION OF THE ELECTRONIC COURT PROCESS",

        // Navbar 
        main: "HOME",
        technopark: "TECHNOPARK",
        education: "EDUCATION",
        startaps: "STARTUPS",
        projects: "PROJECTS",
        digitization: "DIGITIZATION",
        business: "BUSINESS",
        infra: "INFRASTRUCTURE",

        // 
        openbiz: "BUSINESS",
        regBiz: "BUSINESS REGISTRATION",
        servicefee: "Service price",

        // Digization Page
        ourCall: "Contact us",

        // Form
        name: "Name",
        email: "E-mail",
        tel: "Phone",
        comment: "Comment",
        submitap: "Submit an application",
        subm:"SHIPMENT",

        // Startap Page 
        startaptitle: "Directions",
        startapIncub: "Incubation Centers",

        // Events
        events: "EVENTS",

        // Cyber 
        cyber: "CYBERSPORTS",

        // Bisness Page
        bisnesopen: "OPENING A BUSINESS",
        bisn: "Business registration",

        // Infra
        infrabt: "Infrastructure",

        // Contact
        con: "COMMUNICATION",

        // Texno Page
        dc: "Digital city",
        in: "Take a step closer to innovation",
        audition: "Our Audience",

        // Dc info
        infoadress: "Address",
        infoemail: "Phone Number",
        infotell: "E-mail",

        // 
        coWor1:"Technopark serves more than 50 new information technologies intended for placement of enterprises showing. This is it necessary infrastructure for enterprises to carry out their activities in the technological park, and office spaces are provided, financial, marketing, legal and other consulting services are provided to them.",
        coWor2:"Our Coworking spaces are open concept and available to anyone who is a resident. Complemented by comfortable furniture, shared technology such as printers and Wi-Fi and even complimentary tea, coffee/water and snacks to support a collaborative and social environment.", 
    },
    ru: {

        // Globl
        sendBtn: "Отправьте заявку",
        betSil: "Подробнее",
        ourAdress: "Наш адресс",
        yoz:"Отправьте заявку",
        duration: "Продолжительность",
        month: "месяц",

        text1: "РАЗВИТИЕ И ЛИБЕРАЛИЗАЦИЯ ЭКОНОМИКИ: ВНЕДРЕНИЕ ИНФОРМАЦИОННО-КОММУНИКАЦИОННЫХ ТЕХНОЛОГИЙ В ЭКОНОМИКУ, СОЦИАЛЬНУЮ СФЕРУ, СИСТЕМУ УПРАВЛЕНИЯ. СОДЕЙСТВИЕ РАЗВИТИЮ МАЛОГО БИЗНЕСА И ЧАСТНОГО ПРЕДПРИНИМАТЕЛЬСТВА",
        text2: `Совершенствование системы государственного управления: обеспечение прозрачности государственных органов, совершенствование системы «электронного правительства», повышение ее качества`,
        text3: "обеспечение мира, религиозной толерантности и межнационального согласия",
        text4: "ОБЕСПЕЧЕНИЕ ПРАВОПОРЯДКА, РЕФОРМИРОВАНИЕ СУДЕБНОЙ СИСТЕМЫ",
        text5: `ПРОЕКТ РЕШЕНИЯ СУДА МИНИСТРОВ РЕСПУБЛИКИ УЗБЕКИСТАН «О МЕРАХ ПО СОВЕРШЕНСТВОВАНИЮ ПРЕДОСТАВЛЕНИЯ ГОСУДАРСТВЕННЫХ ЭЛЕКТРОННЫХ УСЛУГ ЧЕРЕЗ ЕДИНЫЙ ПОРТАЛ ИНТЕРАКТИВНЫХ ГОСУДАРСТВ»`,
        text6: "ЗАПУСК ЭЛЕКТРОННОГО ЛИЦЕНЗИОННОГО ПОРТАЛА НА 40 ВИДОВ ДЕЯТЕЛЬНОСТИ",
        text7: `ПРОЕКТ ПОСТАНОВЛЕНИЯ ПРЕЗИДЕНТА РЕСПУБЛИКИ УЗБЕКИСТАН «О МЕРАХ ПО ДАЛЬНЕЙШЕМУ РАЗВИТИЮ СИСТЕМЫ «ЭЛЕКТРОННОЕ ПРАВИТЕЛЬСТВО» В 2018-2021 ГГ.»`,
        text8: "СОЗДАНИЕ ИННОВАЦИОННОГО ЦЕНТРА ДЛЯ СОДЕЙСТВИЯ ПРОИЗВОДСТВУ ПРОГРАММНЫХ ПРОДУКТОВ",
        text9: "СОЗДАНИЕ БИЛЛИНГ-СИСТЕМЫ АВТОМАТИЗИРОВАННОЙ ИНФОРМАЦИИ И ПЛАТЕЖЕЙ ЗА ГОСУСЛУГИ В ЦЕНТРАХ «ЕДИНОГО ОКНА»",
        text10: "УСТАНОВКА 250 ИНФОКИОСКОВ В ЦЕНТРАХ «ЯГОНА ДАРЧА» И ПОДРАЗДЕЛЕНИЯХ АО «УЗБЕКИСТАН ПОЧТАСИ» В РЕГИОНАХ",
        text11: `МОНИТОРИНГ СИСТЕМЫ ИНФОРМАЦИОННОЙ БЕЗОПАСНОСТИ В РАМКАХ «ЭЛЕКТРОННОЕ ПРАВИТЕЛЬСТВО».`,
        text12: "ВНЕДРЕНИЕ ЕДИНОЙ МЕЖВЕДОМСТВЕННОЙ «КАДРОВОЙ» СИСТЕМЫ УЧЕТА КАДРОВОГО ПОТЕНЦИАЛА В ГОСУДАРСТВЕННЫХ ОРГАНАХ",
        text13: "МОНТАЖ И ВВОД В ЭКСПЛУАТАЦИЮ 66 МОЩНЫХ И 328 МАЛОЙ МОЩНОСТИ ЦИФРОВЫХ ТЕЛЕВИЗИОННЫХ ПЕРЕДАТЧИКОВ НА ТЕРРИТОРИЯХ РЕСПУБЛИКИ",
        text14: "УСТАНОВКА 1843 БАЗОВЫХ СТАНЦИЙ ОПЕРАТОРОВ МОБИЛЬНОЙ СВЯЗИ НА ТЕРРИТОРИЯХ РЕСПУБЛИКИ",
        text15: "МОДЕРНИЗАЦИЯ КОММУТАЦИОННЫХ ЦЕНТРОВ НА ОСНОВЕ IMS-ТЕХНОЛОГИЙ",
        text16: "СТРОИТЕЛЬСТВО 2300 КМ ОПТИЧЕСКИХ ЛИНИИ СВЯЗИ НА ТЕРРИТОРИИ РЕСПУБЛИКИ",
        text17: "ИГРЫ ДЛЯ РАЗУМНОГО УВЕЛИЧЕНИЯ ДОСУГА МОЛОДЕЖИ И УВЕЛИЧЕНИЯ ИХ ВОЗМОЖНОСТЕЙ - НАЦИОНАЛЬНАЯ ОРГАНИЗАЦИЯ МОБИЛЬНОГО ИНТЕРНЕТА",
        text18: "СОВЕРШЕНСТВОВАНИЕ ЗАКОНОДАТЕЛЬНОЙ БАЗЫ В ОБЛАСТИ КИБЕРБЕЗОПАСНОСТИ",
        text19: "СОЗДАНИЕ СОВРЕМЕННЫХ ИНФОРМАЦИОННЫХ ВОЗМОЖНОСТЕЙ И МУЛЬТИМЕДИЙНЫХ ПРОДУКТОВ В СФЕРЕ ОБРАЗОВАНИЯ И ВОСПИТАНИЯ МОЛОДЕЖИ. РАЗРАБОТКА И ПРОДВИЖЕНИЕ НАЦИОНАЛЬНОГО КОНТЕНТА",
        text20: "ПОВЫШЕНИЕ КОМПЬЮТЕРНОЙ ГРАМОТНОСТИ И НАВЫКОВ ДЛЯ ОБУЧЕНИЯ ПОЛЬЗОВАНИЮ ГОСУДАРСТВЕННЫМИ УСЛУГАМИ ЧЕРЕЗ ИНТЕРНЕТ НАСЕЛЕНИЯ И СУБЪЕКТОВ ПРЕДПРИНИМАТЕЛЬСТВА НА ТЕРРИТОРИЯХ РЕСПУБЛИКИ",
        text21: "ВНЕДРЕНИЕ СОВРЕМЕННЫХ ФОРМ И МЕТОДОВ ОСУЩЕСТВЛЕНИЯ ЭЛЕКТРОННОГО СУДЕБНОГО ПРОЦЕССА",

        // Navbar 
        main: "ГЛАВНЫЙ",
        technopark: "ТЕХНОПАРК",
        education: "ОБРАЗОВАНИЕ",
        startaps: "СТАРТАПЫ",
        projects: "СТАРТАПЫ",
        digitization: "ЦИФРОВИЗАЦИЯ",
        business: "БИЗНЕС",
        infra: "ИНФРАСТРУКТУРА",

        // 
        openbiz: "ОТКРЫТ БИЗНЕСА",
        regBiz: "БИЗНЕС",
        servicefee: "Плата за обслуживание",

        // Digization Page
        ourCall: "Свяжитесь с нами",

        // Form
        name: "Имя",
        email: "Эл. адрес",
        tel: "Телефон",
        comment: "Комментарий",
        submitap: "Подавать заявление",
        subm:"ОТПРАВКА",

        // Startap Page 
        startaptitle: "Направления",
        startapIncub: "Инкубационные центры",

        // Events
        events: "СОБЫТИЯ",

        // Cyber 
        cyber: "КИБЕРСПОРТ",

        // Bisness Page
        bisnesopen: "БИЗНЕС",
        bisn: "Регистрация бизнеса",

        // Infra
        infrabt: "ИНФРАСТРУКТУРА",

        // Contact
        con: "КОММУНИКАЦИЯ",

        // Texno Page
        dc: "Цифровой город",
        in: "Сделать шаг ближе к инновациям",
        audition: "Наша аудитория",

        // Dc info
        infoadress: "Адрес",
        infoemail: "Номер телефона",
        infotell: "Эл. почта",

        // 
        coWor1:"Технопарк обслуживает более 50 новых информационных технологий предназначен для размещения предприятий показ. Это оно необходимая инфраструктура для осуществления предприятиями своей деятельности в технопарке, и предоставляются офисные помещения, им оказываются финансовые, маркетинговые, юридические и другие консультационные услуги.",
        coWor2:"Наши коворкинг-пространства имеют открытую концепцию и доступны для всех, кто является резидентом. В дополнение к удобной мебели, общим технологиям, таким как принтеры и Wi-Fi, и даже бесплатным чаю, кофе/воде и закускам для поддержки совместной и социальной среды.", 
    }
}

export default config