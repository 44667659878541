// Import React 
import React, { useContext, useState } from "react";

// Import Components 
import "../Texnoparkcom/Texnoparkcom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

export default function Texnoparkcom({ texno }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="texnoparkcom">
                <Container>
                    <div className="texnoparkcom__content">
                        <div>
                            <h4 className="texnoparkcom__title" data-aos="fade-up"
                                data-aos-offset="300"
                                data-aos-duration="1000">
                                {lang == "uz" ? texno?.title_uz : lang !== "ru" ? texno?.title_en : texno?.title_ru}
                            </h4>
                            <p className="texnoparkcom__desc" data-aos="fade-up"
                                data-aos-offset="300"
                                data-aos-duration="2000">
                                {lang == "uz" ? texno?.desc_uz : lang !== "ru" ? texno?.desc_en : texno?.desc_ru}
                            </p>
                        </div>

                        <div data-aos="zoom-in-down" data-aos-offset="500" data-aos-duration="2000">
                            <img className="texnoparkcom__img" src={`${httpUrl}${texno?.image}`} alt="img" />
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}
