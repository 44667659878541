// Import React
import React, { useContext } from "react";

// Import Components
import "../ITtalimherocom/ITtalimherocom.scss";
import Container from "../../Container/Container";
import Asidemenu from "../../Asidemenu/Asidemenu";
import Tell from "../../Tell/Tell";
import config from "../../../Localization/localization";
import { Context } from "../../../Context/LangContext";
import VideoPlayer from "react-background-video-player";

export default function ITtalimherocom({video}) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="ittalimcom">
                <Container>
                    <div className="ittalimcom__content">
                        <div className="ittalimcom__asidemenu-wrap">
                            <Asidemenu />
                        </div>


                        <div className="ittalimcom__wrap">
                            <div className="ittalimcom__bg-wrap">
                                <div className="ittalimcom__bg-title" data-aos="fade-zoom-in"
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="300"
                                    data-aos-duration="3000"
                                    data-aos-offset="0">{config[lang].education}</div>
                                <h1 className="ittalimcom__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].education}</h1>
                            </div>

                            <div className="ittalimcom__content-text" data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-offset="0">
                                <VideoPlayer
                                    className="ittalimcom__video"
                                    src={`${httpUrl}${video}`}
                                    autoPlay={true}
                                    muted={true}
                                    
                                    />
                                    {/* <video className="ittalimcom__video" src={"https://firebasestorage.googleapis.com/v0/b/abdullokh-93960.appspot.com/o/ttayyor_oxiri_10000%25.mp4?alt=media&token=43fa057e-75f7-435b-b368-3f5a3860e516"} autoplay loop muted>
                                        
                                    </video> */}
                            </div>
                        </div>


                    </div>

                    <div className="ittalimcom__tel-wrap">
                        <Tell />
                    </div>
                </Container>
            </section>
        </>
    )
}