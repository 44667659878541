// Import React
import React, { useEffect, useState, CSSProperties, useContext } from "react";
import "../CursModal/CursModal.scss";
import Swal from 'sweetalert2';
import config from "../../Localization/localization";
import { Context } from "../../Context/LangContext";

function CursModal({ elCursForm }) {

    const { lang, setLang } = useContext(Context);

    const [data, setData] = useState();
    const [curs, setCurs] = useState('');
    const [user, setUserName] = useState('');
    const [tel, setTel] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
        fetch('https://eduapp.uz/api/courses').then((response) => {
            return response.json()
        }).then((response) => {
            setData(response)
        }).catch(err => console.log(err))
    }, []);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    function onSubmit(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", user);
        formdata.append("phone", tel);
        formdata.append("course_id", curs);
        formdata.append("address", comment);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: myHeaders,
            redirect: 'follow'
        };

        if (tel == '' || user == '' || comment == '') {
            Swal.fire(
                `Ma'lumot Toldrin`,
            )
        } else {
            fetch('https://eduapp.uz/api/appeals', requestOptions)
                .then(response => {
                    if (response.ok) {
                        Swal.fire({
                            icon: 'success',
                            title: `Ma'lumot jonatildi tez orada sizblan bog'lanamiz`,
                            showConfirmButton: false,
                            timer: 10000,
                        })
                        setComment('')
                        setUserName('')
                        setTel('')
                    }else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Xatolik Yuzberdi',
                        })
                    }
                })
                .catch(error => Swal.fire({
                    icon: 'error',
                    title: 'Xatolik Yuzberdi',
                }));
        }
    }

    return (
        <>
            <div className="form-curs" ref={elCursForm} onClick={(evt) => {
                        if (
                            evt.target.matches(".form-curs") || evt.target.matches(".form-modal__btn-clos")
                        ) {
                            elCursForm.current.classList.remove("open--form");
                            document.body.style.overflow = "auto";
                        }
                    }}>
                <form className="form-curs__form">
                    <button type="button" className="form-modal__btn-clos">✖</button>

                    <select className="form-curs__select" name="" id="" placeholder="Curs" onChange={(e) => setCurs(e.target.value)}>
                        {
                            data?.map((dat) => {
                                return (
                                    <>
                                        <option className="form-curs__option" value={dat?.id}>{dat?.name}</option>
                                    </>
                                )
                            })
                        }
                    </select>

                    <input className="form-curs__input" type="text" placeholder={config[lang].name} value={user} onChange={(e) => setUserName(e.target.value)}/>
                    <input className="form-curs__input" type="number" placeholder={config[lang].tel} value={tel} onChange={(e) => setTel(e.target.value)}/>
                    <textarea className="form-curs__textarea" cols="30" rows="10" placeholder={config[lang].infoadress} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                    <button className="form-curs__btn" onClick={(e) => onSubmit(e)}>{config[lang].subm}</button>
                </form>
            </div>
        </>
    )
}

export default CursModal