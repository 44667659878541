// Import React 
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import './App.css';
import Home from './Pages/Home/Home';
import InfraPage from './Pages/InfraPage/InfraPage';
import TexnoPark from './Pages/TexnoPage/TexnoPage';
import ITtalim from './Pages/ITtalim/ITtalim';
import StartapsPage from './Pages/StartapsPage/StartapsPage';
import DigitizationPage from './Pages/DigitizationPage/DigitizationPage';
import BusinessPage from './Pages/BusinessPage/BusinessPage';
import EventsPage from './Pages/EventsPage/EventsPage';
import EventsProf from './Pages/EventsProf/EventsProf';
import Loader from './Components/Loader/Loader';


function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setShow(!show);
    }, 6700)
  }, [])

  if (loading) return <Loader />

  return (
    <>

      <AnimatePresence>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/infrastructure' element={<InfraPage />} />
          <Route path='/texnopark' element={<TexnoPark />} />
          <Route path='/ittalim' element={<ITtalim />} />
          <Route path='/startaps' element={<StartapsPage />} />
          <Route path='/digitizayion' element={<DigitizationPage />} />
          <Route path='/business' element={<BusinessPage />} />
          <Route path='/events' element={<EventsPage />} />
          <Route path='/eventsprof/:contentId' element={<EventsProf />} />
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default App