// Import React 
import React, { useEffect, useState } from "react";

// Import Packages
import { motion } from "framer-motion"

// Import Components
import Header from "../../Components/Header/Header";
import Homhero from "../../Components/Homhero/Homhero";
import Texnopark from "../../Components/Texnopark/Texnopark";
import Education from "../../Components/Education/Education";
import Startaps from "../../Components/Startaps/Startaps";
import Projects from "../../Components/Projects/Projects";
import Cybersport from "../../Components/Cybersport/Cybersport";
import Digitization from "../../Components/Digitization/Digitization";
import Business from "../../Components/Business/Business";
import Infra from "../../Components/Infra/Infra";
import Contact from "../../Components/Contact/Contact";
import Tad from "../../Components/Tad/Tad";

function Home() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    useEffect(() => {
    fetch(`${url}asosiy`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, [])
    
    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }

    return (
        <>
            <motion.section className="scroll-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <Header />
                <Homhero ob={data?.head} />
                <Texnopark ob={data?.texnopark} />
                <Startaps startap={data?.startap} />
                <Education edu={data?.it} />
                <Tad tadData={data?.tadbirlar} />
                <Cybersport ob={data?.kiber} />
                <Digitization numData={data?.raqam} />
                <Business busData={data?.biznes} />
                <Infra infra={data?.iContent} infraItitle={data?.iTitle} />
                <Projects proData={data?.pContent} pData={data?.pTitle}/>
                <Contact conData={data?.contact} />
            </motion.section>
        </>
    )
}

export default Home