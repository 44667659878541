// Import React
import React, { useState, useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Pack
import ScrollTrigger from "react-scroll-trigger";
import CountUp from 'react-countup';

// Import Components
import "../Texnopark/Texnopark.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function Texnopark({ ob }) {

    const { lang, setLang } = useContext(Context);
    const [counterOn, setConunterOn] = useState(false);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <ScrollTrigger onEnter={() => setConunterOn(true)} onExit={() => setConunterOn(false)}>
                <section className="texnopark">
                    <Container>
                        <div className="texnopark__wrapper">
                            <div className="texnopark__wrap-set">
                                <div className="texnopark__bg-title" data-aos="fade-zoom-in"
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="300"
                                    data-aos-duration="3000"
                                    data-aos-offset="0">{config[lang].technopark}</div>
                                <h2 className="texnopark__title" data-aos="zoom-in-up">{config[lang].technopark}</h2>
                            </div>


                            <ul className="texnopark__list">
                                {
                                    ob?.map((dat) => {
                                        return (
                                            <>
                                                <li className="texnopark__item"
                                                    data-aos="fade-up"
                                                    data-aos-duration="1000"
                                                    data-aos-offset="200"
                                                    data-aos-easing="ease-in-sine">
                                                    <div className="texnopark__item-box">
                                                        <img className="texnopark__item-icon" src={`${httpUrl}${dat.icon}`} alt="icon" width={65} height={65} />
                                                        <span>+{counterOn && <CountUp start={0} end={dat.number} duration={6} delay={0} />}</span>
                                                    </div>
                                                    <p className="texnopark__item-text">{lang == "uz" ? dat?.desc_uz : lang !== "ru" ? dat?.desc_en : dat?.desc_ru}</p>
                                                </li>
                                            </>
                                        )
                                    })
                                }
                            </ul>

                            <div className="links">
                                <Link to={"/texnopark"} className="texnopark__link-btn">{config[lang].betSil}</Link>
                            </div>
                        </div>
                    </Container>
                </section>
            </ScrollTrigger>
        </>
    )
}

export default Texnopark