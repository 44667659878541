// Import React 
import React, { useEffect, useState } from "react";

// Import Pack
import { motion } from "framer-motion"

// Import Components
import Header from "../../Components/Header/Header";
import TexnocomHero from "../../Components/TexnoparkComponents/TexnocomHero/TexnocomHero";
import Ourmissioncom from "../../Components/TexnoparkComponents/Ourmissioncom/Ourmissioncom";
import Residentscom from "../../Components/TexnoparkComponents/Residentscom/Residentscom";
import Residenttwo from "../../Components/TexnoparkComponents/Residenttwo/Residenttwo";
import Groupcom from "../../Components/TexnoparkComponents/Groupcom/Groupcom";
import GroupcomCyber from "../../Components/TexnoparkComponents/GroupcomCyber/GroupcomCyber";
import Coworking from "../../Components/TexnoparkComponents/Coworking/Coworking";
import Infrastructure from "../../Components/TexnoparkComponents/Infrastructure/Infrastructure";
import Ouraddress from "../../Components/TexnoparkComponents/Ouraddress/Ouraddress";


function TexnoPark() {

    let url = process.env.REACT_APP_URL;

    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}texnopark`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, []);

    const infraPageStyle = {
        width: '100%',
        backgroundSize: 'cover',
    }

    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }

    return (
        <>
            <motion.section style={infraPageStyle} className="texno-page-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <Header />
                <TexnocomHero />
                <Ourmissioncom data={data?.auditory} ourData={data?.missiya} />
                <Residentscom rezidentConData={data?.rezidentCard} rezidentCon={data?.rezidentContent} />
                <Residenttwo reziMenuData={data?.rezidentMenu} />
                <Groupcom group={data?.jamoaTitle} groupImgData={data?.jamoaCard} />
                <GroupcomCyber cyberData={data?.cyber} />
                <Coworking coTitle={data?.workingTitle} workingContent={data?.workingContent} /> 
                <Ouraddress />
            </motion.section>
        </>
    )
}

export default TexnoPark

