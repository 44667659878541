// Import React 
import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";

// Import Pack
import Select, { NonceProvider } from 'react-select';

// Import Components
import "../../Components/Navbar/Navbar.scss";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function Navbar() {

    const { lang, setLang } = useContext(Context);
    const [selectedOption, setSelectedOption] = useState(null);

    const navRef = useRef();

    const showNav = () => {
        navRef.current.classList.toggle("res_nav");

        if (navRef.classList == 'res_nav') {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }

    const showClos = () => {
        navRef.current.classList.toggle("res_nav");
        document.body.style.overflow = "auto";
    }

    const options = [
        { value: 'uz', label: 'uz' },
        { value: 'en', label: 'en' },
        { value: 'ru', label: 'ru' },
    ];

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
            // borderBottom: '1px dotted pink',
            // color: state.selectProps.menuColor,
            // padding: 5,
            color: '#fff',
            background: '#000',
            cursor: 'pointer',
            textAlign: 'center',
        }),

        control: () => ({
            width: 76,
            height: 31,
            color: '#fff',
            border: '1px solid #cccccc',
            borderRadius: 23,
        }),

        indicatorsContainer: () => ({
            display: 'none',
        }),

        defaultValue: () => ({

            color: '#fff',
        }),

        placeholder: () => ({
            color: '#fff',
        }),
    }

    const Selecting = (e) => {
        setLang(e.value)
    }

    // window.onscroll = function () { 

    //     if (window.scrollY >= 30 ) {
    //         navRef.current.style.backdropFilter = 'blur(50px)'

    //      //   console.log("if",window.scrollY );
    //     } 
    //     else if(window.scrollY <30){
    //         navRef.current.style.backdropFilter = 'blur(50px)'
    //       //  console.log("else if",window.scrollY );
    //     }
    // };

    // Todo Eslatma Localizatsiyani oxiriga etgazish

    return (
        <>
            <nav className="nav" ref={navRef}>
                <ul className="nav__list">
                    <li className="nav__item">
                        <NavLink to={"/"} className="nav__link">{config[lang].main}</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink to={"/texnopark"} className="nav__link">{config[lang].technopark}</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink to={"/ittalim"} className="nav__link">{config[lang].education}</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink to={"/startaps"} className="nav__link">{config[lang].startaps}</NavLink>
                    </li>
                    {/* <li className="nav__item">
                        <NavLink to={"/"} className="nav__link">LOYIHALAR</NavLink>
                    </li> */}
                    <li className="nav__item">
                        <NavLink to={"/digitizayion"} className="nav__link">{config[lang].digitization}</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink to={"/business"} className="nav__link">{config[lang].business}</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink to={"/infrastructure"} className="nav__link">{config[lang].infra}</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink to={"/events"} className="nav__link">{config[lang].events}</NavLink>
                    </li>
                </ul>

                <button className="nav__clos-burber" onClick={showClos}>✖</button>

                <Select
                styles={customStyles}
                defaultValue={lang}
                onChange={(evt) => Selecting(evt)}
                options={options}
                isSearchable={false}
                width='76px'
                menuColor='#fff'
                placeholder={lang}
            />
            </nav>

            <button className="nav__menu" id="hamburger-icon" onClick={showNav}>
                <div>
                    <span className="line line-1" />
                    <span className="line line-2" />
                    <span className="line line-3" />
                </div>
            </button>

            
        </>
    )
}

export default Navbar
