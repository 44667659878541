// Import React
import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Header/Header.scss";
import Container from "../Container/Container";
import Logo from "../../Assets/Img/logo-1.svg";
import Navbar from "../Navbar/Navbar";

function Header() {

    const [header, setHeader] = useState(false);


    const changeBaz = () => {
        if (window.scrollY >= 80) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    };

    window.addEventListener('scroll', changeBaz);

    return (
        <>
            <header className={header ? 'header activ' : 'header'}>
                <Container>
                    <div className="header__wrapper">
                        <Link to={"/"} className="header__logo-link">
                            <img className="header__logo-img" src={Logo} alt="logo" width={128} height={46} />
                        </Link>
                        <Navbar />
                    </div>
                </Container>
            </header>

        </>
    )
}

export default Header