// Import React 
import React,{useState, useEffect} from "react";

// Import Pack
import { motion } from "framer-motion"

// Import Components
import Header from "../../Components/Header/Header";
import Infracom from "../../Components/InfraComponents/Infracom/Infracom";
import Itcentercom from "../../Components/InfraComponents/Itcentercom/Itcentercom";
import Texnoparkcom from "../../Components/InfraComponents/Texnoparkcom/Texnoparkcom";
import Strategycom from "../../Components/InfraComponents/Strategycom/Strategycom";
import ITcompaniescom from "../../Components/InfraComponents/ITcompaniescom/ITcompaniescom";

function InfraPage() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    useEffect(() => {
        fetch(`${url}infro`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, []);

    const infraPageStyle = {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
    }

    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }

    return (
        <>
            <motion.section style={infraPageStyle} className="infra-page-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <Header />
                <Infracom infra={data?.digital} />
                <Itcentercom itcenterData={data?.ititle} iContentData={data?.icontent} />
                <Texnoparkcom texno={data?.markaz} />
                <Strategycom strategData={data?.strategiy} />
                <ITcompaniescom compoData={data?.cTitle} comData={data?.cContent} />
            </motion.section>
        </>
    )
}

export default InfraPage