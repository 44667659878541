// Import React 
import React, { useContext, useState } from "react";
import { NavLink as Link, useLocation, useParams } from "react-router-dom";

// Import Components
import "../EventsProfil/EventsProfil.scss";
import Container from "../Container/Container";
import config from "../../Localization/localization";
import { Context } from "../../Context/LangContext";


function EventsProfil() {

    const { lang, setLang } = useContext(Context);
    const { contentId } = useParams();
    const [data, setData] = useState({});
    const {state} = useLocation()

    return (
        <>
            <section className="eventsprof">
                <Container>
                    <div className="eventsprof__bg-wrap">
                        <div className="eventsprof__bg-title"
                            data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{state?.title_uz}</div>
                        <h1 className="eventsprof__title" data-aos="zoom-in-up">{state?.title_uz}</h1>
                    </div>

                    <div>
                        <p dangerouslySetInnerHTML={{ __html: state?.tadbir }}></p>
                    </div>

                </Container>
            </section>
        </>
    )
}

export default EventsProfil