// Import React
import React, { useState, useEffect } from "react";

// Import Pack
import { motion } from "framer-motion";

// Import Components
import Header from "../../Components/Header/Header";
import BusinesscomHero from "../../Components/BusinessComponents/BusinesscomHero/BusinesscomHero";
import BusinessSection from "../../Components/BusinessComponents/BusinessSection/BusinessSection";
import ServicePrice from "../../Components/BusinessComponents/ServicePrice/ServicePrice";
import FormModal from "../../Components/FormModal/FormModal";


function BusinessPage() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    useEffect(() => {
        fetch(`${url}biznes`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, []);

    const infraPageStyle = {
        width: '100%',
        height: '100vh',
        backgroundSize: 'cover',
    }

    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
            transition: 'all 3s ease',
        }
    }

    const pageDuration = {
        duration: 1,
    }

    const elFormModal = React.useRef();

    function onFunction() {
        elFormModal.current.classList.add("open--modal");
        document.body.style.overflow = "hidden";
    }

    return (
        <>
            <motion.section style={infraPageStyle} className="business-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <div className="business-bg">
                    <Header />
                    <BusinesscomHero headTitle={data?.headTitle} headDataCard={data?.hedrCard} />
                    <BusinessSection contentData={data?.content} contentCard={data?.conentCard}/>
                    <ServicePrice priceData={data?.price} />
                </div>

            </motion.section>
        </>
    )
}

export default BusinessPage