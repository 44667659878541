// Import React
import React, { useRef, useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Homhero/Homhero.scss";
import Container from "../Container/Container";
import Asidemenu from "../Asidemenu/Asidemenu";
import Player from "../Player/Player";
import config from "../../Localization/localization";
import { Context } from "../../Context/LangContext";
import Tell from "../Tell/Tell";

function Homhero({ ob }) {

    const { lang, setLang } = useContext(Context);
    const elVideoMadel = useRef();
    const newData = []

    ob?.forEach(dat => {
        newData.push(dat)
    })

    return (
        <>
            <Player elVideoModal={elVideoMadel} link={newData[0]?.link} />

            <section className="homhero">
                <Container>

                    <div className="homhero__box">
                        <div className="homhero__asidemenu-wrap">
                            <Asidemenu />
                        </div>

                        <div>
                            <h1 className="homhero__title">
                                {lang == "uz" ? newData[0]?.title_uz : lang !== "ru" ? newData[0]?.title_en : newData[0]?.title_ru}
                            </h1>
                            <div className="links">
                                <Link to={"/infrastructure"} className="homhero__btn">{config[lang].infrabt}</Link>
                            </div>
                        </div>
                        <button className="homhero__btn-video" onClick={() => {
                            elVideoMadel.current.classList.add('open--modal');
                            document.body.style.overflow = "hidden";
                        }}></button>
                    </div>

                    <div className="homhero__tell-wrap">
                        <Tell />
                    </div>
                </Container>

            </section>
        </>
    )
}

export default Homhero