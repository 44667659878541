// Import React
import React, { useContext, useState } from "react";

// Import Components
import "../Interests/Interests.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

// Import Pack
import ScrollTrigger from "react-scroll-trigger";
import CountUp from 'react-countup';


export default function Interests({ intData, title }) {

    let httpUrl = process.env.REACT_APP_HTTPS;

    const { lang, setLang } = useContext(Context);
    const [counterOn, setConunterOn] = useState(false);


    return (
        <>
            <ScrollTrigger onEnter={() => setConunterOn(true)} onExit={() => setConunterOn(false)}>
                <section className="interests">
                    <Container>
                        <h2 className="interests__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">
                            {lang == "uz" ? title?.qiziqish_uz : lang !== "ru" ? title?.qiziqish_en : title?.qiziqish_ru}
                        </h2>

                        <div className="interests__content">
                            <ul className="interests__list">
                                {
                                    intData?.map((data) => {
                                        return (
                                            <>
                                                <li>
                                                    <img data-aos="zoom-out" data-aos-duration="2500" src={`${httpUrl}${data?.image}`} alt="img" />
                                                    <p data-aos="fade-up"
                                                        data-aos-duration="3000">{lang == "uz" ? data?.title_uz : lang !== "ru" ? data?.title_en : data?.title_ru} <span>{counterOn && <CountUp start={0} end={data?.number} duration={10} delay={0} />}</span>%</p>
                                                </li>
                                            </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Container>
                </section>
            </ScrollTrigger>
        </>
    )
}