// Import React
import React, { useContext } from "react";
import { NavLink as Link } from "react-router-dom";
import { Context } from "../../Context/LangContext";

// Import Pack
import CountUp from 'react-countup';


// Import Components
import "../Infra/Infra.scss";
import Container from "../Container/Container";

// Import Img and Icon
import Img from "../../Assets/Icon/infra.svg";
import config from "../../Localization/localization";


function Infra({ infra, infraItitle }) {

    const { lang, setLang } = useContext(Context);

    const newData = [];

    infraItitle?.map((dat) => {
        newData.push(dat)
    })

    console.log(infraItitle);

    return (
        <>
            <section className="infra">
                <Container>
                    <div className="infra__bg-wrap">
                        <div className="infra__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].infra}</div>
                        <h2 className="infra__title" data-aos="zoom-in-up">
                            {lang == "uz" ? newData[0]?.title_uz : lang !== "ru" ? newData[0]?.title_en : newData[0]?.title_ru}
                        </h2>
                    </div>

                    <div className="infra__box">
                        <img data-aos="zoom-in" data-aos-duration="1000" className="infra__img" src={Img} alt="img" />

                        {
                            infra?.map((dat) => {
                                return (
                                    <>
                                        <div className="infra__num-box">
                                            <div className="infra__num-year"><CountUp start={0} end={dat?.year} duration={3} delay={0} /></div>
                                            <div className="infra__num-number"><CountUp start={0} end={dat?.number} duration={5} delay={0} />ta</div>
                                        </div>
                                    </>
                                )
                            })
                        }

                        <div className="infra__wrap">
                            <p className="infra__desc" data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? newData[0]?.desc_uz : lang !== "ru" ? newData[0]?.desc_en : newData[0]?.desc_ru}
                            </p>
                            <div data-aos="fade-up">
                                <Link to={"/infrastructure"} className={"infra__link-btn"}>{config[lang].betSil}</Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Infra