// Import React
import React, { useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../GroupcomCyber/GroupcomCyber.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

export default function GroupcomCyber({ cyberData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="group-cyber">
                <Container>

                    <div className="group-cyber__content">
                        <img data-aos="fade-up"
                            data-aos-duration="3000" src={`${httpUrl}${cyberData?.image}`} alt="img" />

                        <div className="group-cyber__box">
                            <h4 data-aos="fade-up"
                                data-aos-duration="1000">
                                {lang == "uz" ? cyberData?.title_uz : lang !== "ru" ? cyberData?.title_en : cyberData?.title_ru}
                            </h4>
                            <div>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000">
                                    {lang == "uz" ? cyberData?.desc_uz : lang !== "ru" ? cyberData?.desc_en : cyberData?.desc_ru}
                                </p>
                                <div className="group-cyber__wrap-btn" data-aos="fade-up">
                                    <Link to={"/ittalim"} className={"group-cyber__link"}>{config[lang].betSil}</Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </Container>
            </section>
        </>
    )
}