// Import React 
import React, { useContext, useState } from "react";

// Import Components
import "../DigitizationOurcall/DigitizationOurcall.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

// Import Pack
import Swal from 'sweetalert2'

export default function DigitizationOurcall() {

    let url = process.env.REACT_APP_URL;

    const { lang, setLang } = useContext(Context);

    const [userName, setUserName] = useState('');
    const [tell, setTell] = useState('');
    const [comment, setComment] = useState('');
    const [email, setEmail] = useState('');


    function onSubmit(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", userName);
        formdata.append("tel", tell);
        formdata.append("email", email);
        formdata.append("message", comment);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        if (tell == '' || userName == '' || comment == '' || email == '') {
            Swal.fire(
                'Malumot Toldrin',
            )
        } else {
            fetch(`${url}message/`, requestOptions)
                .then(response => response.text())
                .then(function (response) {
                    if (response == 1) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Malumot jonatildi tez orada sizblan boglanamiz',
                            showConfirmButton: false,
                            timer: 10000,
                        })
                        setComment('')
                        setUserName('')
                        setTell('')
                        setEmail('')
                    }
                })
                .catch(error => Swal.fire({
                    icon: 'error',
                    title: 'Xatolik Yuzberdi',
                }));
        }
    }

    return (
        <>
            <section className="digitization-ourcall">
                <Container>

                    <h2 className="digitization-ourcall__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].ourCall}</h2>

                    <form className="digitization-ourcall__form">
                        <input className="digitization-ourcall--input" type="text" value={userName} placeholder={config[lang].name} onChange={(e) => setUserName(e.target.value)} />
                        <input className="digitization-ourcall--input" type="number" value={tell} placeholder={config[lang].tel} onChange={(e) => setTell(e.target.value)} />
                        <input className="digitization-ourcall--input input-buttom" value={email} required type="email" placeholder={config[lang].email} onChange={(e) => setEmail(e.target.value)} />
                        <textarea className="digitization-ourcall--textarea" value={comment} placeholder={config[lang].comment} onChange={(e) => setComment(e.target.value)}></textarea>

                        <button onClick={(e) => onSubmit(e)}>{config[lang].submitap}</button>
                    </form>

                </Container>
            </section>
        </>
    )
}