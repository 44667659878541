// Import React 
import React from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Asidemenu/Asidemenu.scss";

// Import Icon
import IconMenu from "../../Assets/Icon/Add-menu.svg";
import IconEdu from "../../Assets/Icon/educat.svg";
import IconRocket from "../../Assets/Icon/rocket.svg";
import IconCall from "../../Assets/Icon/call.svg";

function Asidemenu() {
    return (
        <>
            <section className="aside-menu">
                <ul className="aside-menu__list">
                    <li className="aside-menu__item">
                        <Link to={"/"} className="aside-menu__item-link">
                            <img className="aside-menu__item-icon" src={IconMenu} alt="menu-icon" />
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link to={"/ittalim"} className="aside-menu__item-link">
                            <img className="aside-menu__item-icon" src={IconEdu} alt="edu-icon" />
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link to={"/digitizayion"} className="aside-menu__item-link">
                            <img className="aside-menu__item-icon" src={IconRocket} alt="rocket-icon" />
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <a href="tel: 1227" className="aside-menu__item-link">
                            <img className="aside-menu__item-icon" src={IconCall} alt="call-icon" />
                        </a>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default Asidemenu