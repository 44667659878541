// Import React 
import React, { useContext } from "react";

// Import Components
import "../Itcentercom/Itcentercom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

// Import Pack
import CountUp from "react-countup";

// Import Img
import Img from "../../../Assets/Img/уxclude.svg";


export default function Itcentercom({ itcenterData, iContentData }) {

    const { lang, setLang } = useContext(Context);

    return (
        <>
            <section className="centercom">
                <Container>
                    <div className="centercom__content">
                        <div className="centercom__wraps">
                            <div data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-duration="500">
                                <img className="centercom__img" src={Img} alt="img" />
                            </div>

                            {
                                iContentData?.map((data) => {
                                    return (
                                        <>
                                            <div className="centercom__wrap-list" key={data.id}>
                                                <p className="centercom__wrap-title">{data?.year}</p>
                                                <p className="centercom__wrap-desc">{<CountUp start={0} end={data?.number} duration={6} delay={0} />}</p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <h4 className="centercom__title" data-aos="fade-up"
                                data-aos-offset="300"
                                data-aos-duration="1000">
                                {lang == "uz" ? itcenterData?.title_uz : lang !== "ru" ? itcenterData?.title_en : itcenterData?.title_ru}
                            </h4>
                            <p className="centercom__desc" data-aos="fade-up"
                                data-aos-offset="300"
                                data-aos-duration="2000">
                                {lang == "uz" ? itcenterData?.desc_uz : lang !== "ru" ? itcenterData?.desc_en : itcenterData?.desc_ru}
                            </p>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )

}