// Import React 
import React, { useContext } from "react";

// Import Components 
import "../StartapscomHero/StartapscomHero.scss";
import Container from "../../Container/Container";
import Asidemenu from "../../Asidemenu/Asidemenu";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";
import Tell from "../../Tell/Tell";

export default function StartapcomHero({ heroData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="startapcom">
                <Container>
                    <div className="startapcom__header-box">
                        <div className="startapcom__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].startaps}</div>
                        <h1 className="startapcom__title">{config[lang].startaps}</h1>
                    </div>

                    <div className="startapcom__asidemenu-wrap">
                        <Asidemenu />
                    </div>

                    <div className="startapcom__content">
                        <div className="startapcom__wrap">
                            <h3 className="startapcom__wrap-title">
                                {lang == "uz" ? heroData?.title_uz : lang !== "ru" ? heroData?.title_en : heroData?.title_ru}
                            </h3>
                            <p>
                                {lang == "uz" ? heroData?.desc_uz : lang !== "ru" ? heroData?.desc_en : heroData?.desc_ru}
                            </p>
                        </div>

                        <img src={`${httpUrl}${heroData?.image}`} alt="img" width={400} height={400} />
                    </div>
                    <div className="startapcom__tel-wrap">
                        <Tell />
                    </div>
                </Container>
            </section>

        </>
    )
}