// Import React
import React,{useState, useEffect} from "react";

// Import Pack
import { motion } from "framer-motion";

// Import Components
import Header from "../../Components/Header/Header";
import ITtalimherocom from "../../Components/ITtalimComponents/ITtalimherocom/ITtalimherocom";
import Interests from "../../Components/ITtalimComponents/Interests/Interests";
import ITacademy from "../../Components/ITtalimComponents/ITacademy/ITacademy";

function ITtalim() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    useEffect(() => {
        fetch(`${url}talim`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, []);
    
    const infraPageStyle = {
        width: '100%',
        backgroundSize: 'cover',
    }

    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }
    return (
        <>
            <motion.section style={infraPageStyle} className="talim-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>
                <Header />
                <ITtalimherocom video={data.video?.video} />
                <Interests intData={data.qiziqishlar} title={data.titles} />
                <ITacademy academData={data.card} data={data.titles}/> 
            </motion.section>
        </>
    )
}

export default ITtalim