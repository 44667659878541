import React, { useEffect, useState, useContext } from 'react'

import "../ProjectSlider/ProjectSlider.scss";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function ProjectSlider({ proData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <section id="brands">
            <div class="marquee">
                <div class="marquee--inner">

                    {
                        proData?.map((data) => {
                            return (
                                <>
                                    <span>
                                        <div class="orb">
                                            <a href={data?.link} target={"_blank"}>
                                                <img src={`${httpUrl}${data?.image}`} alt="img" />
                                                <p>
                                                    {lang == "uz" ? data?.desc_uz : lang !== "ru" ? data?.desc_en : data?.desc_ru}
                                                </p>
                                            </a>
                                        </div>
                                    </span>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
export default ProjectSlider