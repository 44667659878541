// Import React 
import React, { useContext } from "react";

// Import Components
import "../BusinessSection/BusinessSection.scss";
import Container from "../../Container/Container";

import FormModal from "../../FormModal/FormModal";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";

export default function BusinessSection({ contentData, contentCard }) {

    const { lang, setLang } = useContext(Context);
    const elFormModal = React.useRef();
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="business-section">
                <Container>
                    <div className="business-section__wrap">
                        <div data-aos="zoom-out-up" data-aos-duration="3000">
                            <img src={`${httpUrl}${contentData?.image}`} alt="img" width={460} height={460} />
                        </div>

                        <div className="business-section__content">
                            <h3 data-aos='fade-up'>
                                {lang == "uz" ? contentData?.title_uz : lang !== "ru" ? contentData?.title_en : contentData?.title_ru}
                            </h3>

                            <ul className="business-section__list">
                                {
                                    contentCard?.map((data) => {
                                        return (
                                            <>
                                                <li >
                                                    <h4 data-aos="fade-up"
                                                        data-aos-duration="1000">
                                                        {lang == "uz" ? data?.title_uz : lang !== "ru" ? data?.title_en : data?.title_ru}
                                                    </h4>
                                                    <p data-aos="fade-up"
                                                        data-aos-duration="3000">
                                                        {lang == "uz" ? data?.desc_uz : lang !== "ru" ? data?.desc_en : data?.desc_ru}
                                                    </p>
                                                </li>
                                            </>
                                        )
                                    })
                                }
                            </ul>

                            <button data-aos="fade-up">{config[lang].sendBtn}</button>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}