// Import React
import React, { useState, useContext, useEffect, useRef } from "react";

// Import Components
import "../Contact/Contact.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

// Import Pack
import Swal from 'sweetalert2'

import Img1 from "../../Assets/Icon/manzil.svg";
import Img2 from "../../Assets/Icon/contact-call.svg";
import Img3 from "../../Assets/Icon/pochta.svg";

function Contact({ conData }) {

    let url = process.env.REACT_APP_URL;
    const { lang, setLang } = useContext(Context);

    const [userName, setUserName] = useState('');
    const [tell, setTell] = useState('');
    const [comment, setComment] = useState('');

    const [disabled, setDisabled] = useState(false);

    const elFormOver = useRef()
    const elFormBtn = useRef()

    useEffect(() => {
        if (userName.length > 2 && setTell.length > 9) {
            setDisabled(true);
        }
    }, [userName, setTell])

    // ? 
    function onSubmit(e) {
        // console.log('ok');
        e.preventDefault()

        // elInput.value = null


        var formdata = new FormData();
        formdata.append("name", userName);
        formdata.append("tel", tell);
        formdata.append("subject", comment);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        if (tell == '' || userName == '' || comment == '') {
            Swal.fire(
                'Malumot Toldrin',
            )
        } else {
            fetch(`${url}call/`, requestOptions)
                .then(response => response.text())
                .then(function (response) {
                    if (response == 1) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Malumot jonatildi tez orada sizblan boglanamiz',
                            showConfirmButton: false,
                            timer: 10000,
                        })
                        setComment('')
                        setUserName('')
                        setTell('')
                    }
                })
                .catch(error => Swal.fire({
                    icon: 'error',
                    title: 'Xatolik Yuzberdi',
                }));
        }
    }

    return (
        <>
            <section className="contact">
                <Container>

                    <div className="contact__bg-wrap">
                        <div className="contact__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].con}</div>
                        <h2 className="contact__title" data-aos="zoom-in-up">{config[lang].con}</h2>
                    </div>

                    <div className="contact__box">
                        <ul className="contact__list">
                            <li className="contact__item">
                                <img className="contact__item-icon" src={Img1} alt="icon" width={40} />
                                <div className="contact__item-wrap">
                                    <h6 className="contact__item-title">{config[lang].infoadress}</h6>
                                    <p className="contact__item-text">{conData?.manzil}</p>
                                </div>
                            </li>
                            <li className="contact__item">
                                <img className="contact__item-icon" src={Img2} alt="icon" width={40} />
                                <div className="contact__item-wrap">
                                    <h6 className="contact__item-title">
                                        {config[lang].infoemail}
                                    </h6>
                                    <p className="contact__item-text">{conData?.tel}</p>
                                </div>
                            </li>
                            <li className="contact__item">
                                <img className="contact__item-icon" src={Img3} alt="icon" width={40} />
                                <div className="contact__item-wrap">
                                    <h6 className="contact__item-title"> {config[lang].infotell}</h6>
                                    <p className="contact__item-text">{conData?.email}</p>
                                </div>
                            </li>
                        </ul>
                        {/* <Check /> */}

                        <form className="contact__form" data-aos="zoom-in-up" data-aos-easing="linear"
                            data-aos-duration="1500">

                            <h4 className="contact__form-title">{config[lang].con}</h4>

                            <input className="contact__form-input form-input" type="text" placeholder={config[lang].name} name="name" value={userName} onChange={(e) => setUserName(e.target.value)} />
                            <input className="contact__form-input form-input" type="number" placeholder={config[lang].tel} value={tell} onChange={(e) => setTell(e.target.value)} />
                            <textarea className="contact__form-textarea form-input" name="comment" value={comment} onChange={(e) => setComment(e.target.value)} id="comment" cols="30" rows="10" placeholder={config[lang].comment}></textarea>

                            <button className="contact__form-btn" onClick={(e) => onSubmit(e)}>{config[lang].subm}</button>
                        </form>
                    </div>
                </Container>

            </section>
        </>
    )
}

export default Contact