// Import React 
import React, { useState, useContext } from "react";
import { Context } from "../../../Context/LangContext";


// Import Pack
import CountUp from 'react-countup';

// Import Components
import "../Residentscom/Residentscom.scss";
import Container from "../../Container/Container";

export default function Residentscom({ rezidentConData, rezidentCon }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <Container>
                <section className="resident">
                    <div className="resident__content">

                        <div className="resident__box">

                            <div className="resident__wrap">
                                <div className="resident__wrap-title"></div>
                                <h2 className="resident__title" data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
                                    {lang == "uz" ? rezidentConData?.title_uz : lang !== "ru" ? rezidentConData?.title_en : rezidentConData?.title_ru}
                                </h2>
                            </div>

                            <p className="resident__desc" data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                                {lang == "uz" ? rezidentConData?.desc_uz : lang !== "ru" ? rezidentConData?.desc_en : rezidentConData?.desc_ru}
                            </p>
                        </div>

                        <img data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            src={`${httpUrl}${rezidentConData?.image}`} alt="img" width={250} height={300} />
                    </div>

                    <ul className="resident__list" data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="300"
                        data-aos-offset="0">
                        {
                            rezidentCon?.map((dat) => {
                                return (
                                    <>
                                        <li className="resident__item">
                                            <h4 className="resident__item-header">{dat?.number}</h4>
                                            <p className="resident__item-desc">
                                                {lang == "uz" ? dat?.title_uz : lang !== "ru" ? dat?.title_en : dat?.title_ru}
                                            </p>
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>
                </section>
            </Container>
        </>
    )
}