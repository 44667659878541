// Import React
import React,{useEffect, useState} from "react";

// Import Pack
import { motion } from "framer-motion"

// Import Components
import Header from "../../Components/Header/Header";
import DigitizationHero from "../../Components/DigitizationComponents/DigitizationHero/DigitizationHero";
import DigitizationCard from "../../Components/DigitizationComponents/DigitizationCard/DigiyizationCard";
import DigitizationOurcall from "../../Components/DigitizationComponents/DigitizationOurcall/DigitizationOurcall";

function DigitizationPage() {

    const [data, setData] = useState({});
    let url = process.env.REACT_APP_URL;

    useEffect(() => {
        fetch(`${url}raqamlashtirish`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data)
            }).catch(err => console.log(err))
    }, []);
    
    const pageTranslation = {
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    }

    const pageDuration = {
        duration: 1,
    }

    return (
        <>
            <motion.section className="digitization-bg"
                initial='out'
                animate='in'
                exit='out'
                variants={pageTranslation}
                transition={pageDuration}>

                <div className="d">
                    <Header />
                    <DigitizationHero heroContentData={data.card} />
                    <DigitizationCard cardData={data.item} />
                    <DigitizationOurcall />
                </div>

            </motion.section>
        </>
    )
}

export default DigitizationPage