// Import React
import React, { useContext } from "react";

// Import Components
import "../ITcompaniescom/ITcompaniescom.scss";
import Container from "../../Container/Container";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";


export default function ITcompaniescom({ compoData, comData }) {

    let httpUrl = process.env.REACT_APP_HTTPS;
    const { lang, setLang } = useContext(Context);
    const newData = [];

    comData?.map((dat) => {
        newData.push(dat)
    })

    return (
        <>
            <Container>
                <section className="companiescom">
                    <h2 className="companiescom__title" data-aos="fade-up"
                        data-aos-offset="300"
                        data-aos-duration="1000">
                        {lang == "uz" ? compoData?.title_uz : lang !== "ru" ? compoData?.title_en : compoData?.title_ru}
                    </h2>
                    <p className="companiescom__desc" data-aos="fade-up"
                        data-aos-offset="300"
                        data-aos-duration="2000">
                        {lang == "uz" ? compoData?.desc_uz : lang !== "ru" ? compoData?.desc_en : compoData?.desc_ru}
                    </p>

                    <div className="companiescom__wrapper">
                        <h3 className="companiescom__wrapper-title" data-aos="fade-up"
                            data-aos-offset="300"
                            data-aos-duration="800">
                            {lang == "uz" ? newData[0]?.title_uz : lang !== "ru" ? newData[0]?.title_en : newData[0]?.title_ru}
                        </h3>

                        <ul className="companiescom__wrap-list">
                            {
                                newData[0]?.urls.map((data) => {
                                    return (
                                        <>
                                            <li className="companiescom__wrap-item" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src={`${httpUrl}${data?.url}`} alt="img" />
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <div className="companiescom__wrap-two">
                        <ul className="companiescom__wrap2-list">
                            {
                                newData[1]?.urls.map((data) => {
                                    return (
                                        <>
                                            <li className="companiescom__wrap2-item" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src={`${httpUrl}${data?.url}`} alt="img" />
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>

                        <h3 className="companiescom__wrapper-title" data-aos="fade-up"
                            data-aos-offset="300"
                            data-aos-duration="800">
                            {lang == "uz" ? newData[1]?.title_uz : lang !== "ru" ? newData[1]?.title_en : newData[1]?.title_ru}
                        </h3>

                    </div>

                    <div className="companiescom__wrap-three">

                        <h3 className="companiescom__wrapper-title" data-aos="fade-up"
                            data-aos-offset="300"
                            data-aos-duration="800">
                            {lang == "uz" ? newData[2]?.title_uz : lang !== "ru" ? newData[2]?.title_en : newData[2]?.title_ru}
                        </h3>

                        <ul className="companiescom__wrap3-list">
                            {
                                newData[2]?.urls.map((data) => {
                                    return (
                                        <>
                                            <li className="companiescom__wrap2-item" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src={`${httpUrl}${data?.url}`} alt="img" />
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>

                </section>
            </Container>
        </>
    )
}