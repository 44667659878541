// Import React 
import React, { useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Education/Education.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";


function Education({ edu }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;
    
    return (
        <>
            <section className="education">
                <Container>
                    <div className="education__bg-wrap">
                        <div className="education__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].education}</div>
                        <h2 className="education__title" data-aos="zoom-in-up">{config[lang].education}</h2>
                    </div>
                    <div className="education__box">
                        <div>
                            <p className="education__desc" data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? edu?.desc_uz : lang !== "ru" ? edu?.desc_en : edu?.desc_ru}
                            </p>
                            <div data-aos="fade-up">
                                <Link to={"/ittalim"} className={"education__link-btn"}>{config[lang].betSil}</Link>
                            </div>
                        </div>
                        <div data-aos="fade-zoom-in"
                            data-aos-duration="2000">
                            <img className="education__img" src={`${httpUrl}${edu?.image}`} alt="img" width={400} height={400} />
                        </div>
                    </div>


                </Container>
            </section>
        </>
    )
}

export default Education