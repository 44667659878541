// Import React 
import React, { useContext } from "react";

// Import Components 
import "../DigitizationHero/DigitizationHero.scss";
import Container from "../../Container/Container";
import Asidemenu from "../../Asidemenu/Asidemenu";
import { Context } from "../../../Context/LangContext";
import config from "../../../Localization/localization";
import Tell from "../../Tell/Tell";

export default function DigitizationHero({ heroContentData }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="digitizationcom">

                <Container>

                    <div className="digitizationcom__asidemenu-wrap">
                        <Asidemenu />
                    </div>
                    <div className="digitizationcom__bg-wrap">
                        <div className="digitizationcom__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].digitization}</div>
                        <h1 className="digitizationcom__title" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="300">{config[lang].digitization}</h1>
                    </div>

                    <div className="digitizationcom__content animate__fadeInRight">
                        <p data-aos="fade-up"
                            data-aos-duration="2000">
                                {lang == "uz" ? heroContentData?.title_uz : lang !== "ru" ? heroContentData?.title_en : heroContentData?.title_ru}
                        </p>

                        <img className="digitizationcom__img" src={`${httpUrl}${heroContentData?.image}`} alt="img" width={400} height={400} />
                    </div>
                    <div className="digitizationcom__tel-wrap">
                        <Tell />
                    </div>
                </Container>

            </section>
        </>
    )
}