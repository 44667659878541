// Import React
import React, { useContext } from "react";
import { NavLink as Link } from "react-router-dom";

// Import Components
import "../Cybersport/Cybersport.scss";
import Container from "../Container/Container";
import { Context } from "../../Context/LangContext";
import config from "../../Localization/localization";

function Cybersport({ ob }) {

    const { lang, setLang } = useContext(Context);
    let httpUrl = process.env.REACT_APP_HTTPS;

    return (
        <>
            <section className="cybersport">
                <Container>

                    <div className="cybersport__bg-wrap">
                        <div className="cybersport__bg-title" data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-offset="0">{config[lang].cyber}</div>
                        <h2 className="cybersport__title" data-aos="zoom-in-up">{config[lang].cyber}</h2>

                    </div>

                    <div className="cybersport__box">
                        <div className="cybersport__wrap">
                            <p className="cybersport__desc" data-aos="fade-up"
                                data-aos-duration="3000">
                                {lang == "uz" ? ob?.desc_uz : lang !== "ru" ? ob?.desc_en : ob?.desc_ru}
                            </p>
                            <div data-aos="fade-up">
                                <Link to={"/ittalim"} className={"cybersport__link-btn"}>{config[lang].betSil}</Link>
                            </div>
                        </div>

                        <img data-aos="zoom-in" data-aos-duration="3000" className="cybersport__img" src={`${httpUrl}${ob?.image}`} alt="img" width={450} height={400} />
                    </div>

                </Container>
            </section>
        </>
    )
}

export default Cybersport